import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  terminals: [],
  states: [],
  available_states: [],
  terminal_requests: [],
};

const getters = {
  GET_TERMINALS: (state) => state.terminals,
  GET_STATES: (state) => state.states,
  GET_AVAILABLE_STATES: (state) => state.available_states,
  GET_GENERAL_TERMINAL_REQUESTS: (state) => state.terminal_requests,
};

const mutations = {
  SET_TERMINALS: (state, data) => {
    state.terminals = data;
  },
  SET_STATES: (state, data) => {
    state.states = data;
  },
  SET_AVAILABLE_STATES: (state, data) => {
    state.available_states = data;
  },
  SET_GENERAL_TERMINAL_REQUESTS: (state, data) => {
    state.terminal_requests = data;
    console.log(state.terminal_requests);
  },
};

const actions = {
  FETCH_ALL_TERMINALS: async function({ commit }) {
    const httpReq = {
      // const httpReq = {
      // url: `/v1/stores/getallterminals?page=1&record_per_page=100`,
      url: `/v1/stores/admin/terminal/list?limit=100&page=1`,
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_TERMINALS", httpRes);
    return httpRes;
  },
  DOWNLOAD_ALL_TERMINALS_IN_CSV: async function({ commit }) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/stores/admin/terminal/list?limit=100&page=1&download=true`,
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // commit("SET_TERMINALS", httpRes);
    return httpRes;
  },
  SEARCH_ALL_TERMINALS_USING_TERMINAL_ID_OR_SERIAL_NUMBER: async function(
    { commit },
    { search }
  ) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/stores/admin/terminal/list?limit=100&page=1&search=${search}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_TERMINALS", httpRes);
    return httpRes;
  },

  FETCH_ALL_TERMINAL_PRICE: async function({ commit }) {
    const httpReq = {
      url: `/v1/stores/listterminalprice`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  FETCH_ALL_TERMINAL_REQUESTS: async function({ commit }) {
    const httpReq = {
      url: `/v1/stores/listterminalrequest`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_GENERAL_TERMINAL_REQUESTS");
    return httpRes;
  },

  FETCH_DATED_TERMINAL_REQUESTS_ALL: async function(
    { commit },
    { startDate, endDate }
  ) {
    const httpReq = {
      url: `v1/stores/listterminalrequest?startdatetime=${startDate}&enddatetime=${endDate}`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_GENERAL_TERMINAL_REQUESTS");
    return httpRes;
  },

  FETCH_STATES: async function({ commit }) {
    const httpReq = {
      // url: `/v1/admin/agents?limit=${limit}&skip=${page}`,
      url: `/v1/stores/terminal/location/all`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_STATES", httpRes);
    return httpRes;
  },

  FETCH_AVAILABLE_STATES: async function({ commit }) {
    const httpReq = {
      // url: `/v1/admin/agents?limit=${limit}&skip=${page}`,
      url: `/v1/stores/terminal/location/available`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_AVAILABLE_STATES", httpRes);
    return httpRes;
  },
  UPDATE_TERMINAL_LOCATION: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `v1/stores/terminal/location/update`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  REMOVE_TERMINAL_LOCATION: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `v1/stores/terminal/location/remove`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  ADD_TERMINAL_LOCATION: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `v1/stores/terminal/location/add`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  UPLOAD_GA_TERMINALS_VIA_XLS: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `v1/admin/addaccelerexterminals`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  UPLOAD_TERMINALS_VIA_XLS: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `v1/admin/addtractionterminalids`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  NEW_UPDATE_TERMINAL_REQUEST: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/stores/editterminalrequest/${payload.requestId}`,
      method: "PUT",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  UPDATE_TERMINAL_REQUEST: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/stores/updateTerminalRequest`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  FETCH_SINGLE_TERMINAL: async function(context, { id }) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/stores/getterminal/${id}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  FETCH_PAGINATED_TERMINAL: async function({ commit }, { page }) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/stores/admin/terminal/list?limit=100&page=${page}`,
      // url: `/v1/stores/getallterminals?page=${page}&record_per_page=100`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_TERMINALS", httpRes);
    return httpRes;
  },
  FETCH_PAGINATED_REQUESTS_TERMINAL: async function({ commit }, { page }) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/stores/listterminalrequest?page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_TERMINALS", httpRes);
    return httpRes;
  },
  GET_ALL_TERMINAL_PROVIDERS: async function() {
    const httpReq = {
      // const httpReq = {
      url: `/v1/stores/listterminalprovidernames`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  GET_ALL_BANKS: async function() {
    const httpReq = {
      // const httpReq = {
      url: `/v1/stores/listbanknames`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  GET_ALL_BUSINESSES: async function() {
    const httpReq = {
      // const httpReq = {
      url: `/v1/businesses/admin/getallbusinesses`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  GET_BUSINESS_SEARCH_ASYNC: async function(context, search) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/businesses/admin/getallbusinesswithstores?search=${search}&search_by=name`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  FETCH_SINGLE_BUSINESS: async function(context, { business_id }) {
    const httpReq = {
      // const httpReq = {
      // url: `/v1/businesses/${business_id}`,
      url: `/v1/businesses/admin/getallbusinesswithstores?search=${business_id}&search_by=id`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  FETCH_TERMINAL_HISTORY: async function(context, { terminalid, historyType }) {
    const httpReq = {
      url: `v1/admin/terminal/terminal-history/${terminalid}?terminalHistoryType=${historyType}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  CREATE_TERMINAL: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/stores/admin/addterminal`,
      // url: `/v1/stores/admin/new_deployment`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  CREATE_TERMINAL_PRICING: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/stores/addterminalprice`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  UPDATE_TERMINAL_PRICING: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/stores/updateterminalprice`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  UPDATE_TERMINAl: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      // url: `/v1/stores/admin/editterminal`,
      url: `/v1/stores/admin/reassign_terminal`,
      method: "PATCH",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
