import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = () => ({
  notifications: [],
});

const getters = {
  GET_NOTIFICATIONS: (state) => state.notifications,
};

const mutations = {
  SET_NOTIFICATIONS: (state, data) => {
    state.notifications = data;
  },
};

const actions = {
  GET_ALL_NOTIFICATIONS: async function({ commit }) {
    const httpReq = {
      url: `/v1/stores/notification/all`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_NOTIFICATIONS", httpRes);
    return httpRes;
  },

  CREATE_MESSAGE: async function(context, payload) {
    const httpReq = {
      url: `/v1/stores/notification/create`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  UPDATE_MESSAGE: async function(context, payload) {
    const httpReq = {
      url: `/v1/stores/notification/update`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  UPLOAD_FILE: async function(context, payload) {
    const uploadFormData = new FormData();
    uploadFormData.append("file", payload.file);

    const httpReq = {
      url: `/v1/uploads/`,
      method: "POST",
      authRequest: true,
      body: uploadFormData,
      fileUpload: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  PUBLISH_MESSAGE: async function(context, payload) {
    const httpReq = {
      url: `/v1/stores/notification/publish`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
};
