import { HTTP_REQUEST } from '../../http-config/trac-http-req'

const actions = {
  BALANCE_ENQUIRY: async function(context, payload) {
    const httpReq = {
      url: `/v1/transfer/bulk/enquiry/balance?provider=${payload}`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  RE_QUERY: async function(context, payload) {
    const httpReq = {
      url: `/v1/transfer/bulk/sweep/status?reference=${payload}`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  BULK_SWEEP_HISTORY: async function(context, payload) {
    if (payload) {
      payload = new URLSearchParams(payload).toString()
    }

    const httpReq = {
      // const httpReq = {
      url: `/v1/transfer/bulk/history/bulk?${payload}`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  VFD_BULK_SWEEP_HISTORY: async function(context, payload) {
    if (payload) {
      payload = new URLSearchParams(payload).toString()
    }

    const httpReq = {
      // const httpReq = {
      url: `/v1/transfer/bulk/history/bulk?${payload}`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  NAME_ENQUIRY: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `v1/transfer/bulk/enquiry/account?provider=kuda&bank=${payload.bank}&account=${payload.account}`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  GET_BANKS: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/transfer/banks?provider=kuda`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  GET_VFD_BANKS: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v2/loans/loan-tracker/sweep/ft/accounts`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  CREATE_BULK_SWEEP: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/transfer/bulk/sweep/new`,
      method: 'POST',
      authRequest: true,
      body: payload,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  INITIATE_BULK_SWEEP: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/transfer/bulk/sweep/process`,
      method: 'POST',
      authRequest: true,
      body: payload,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  SWEEP_REQUEST: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/transfer/bulk/history/sweep?reference=${payload}`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },
}

export default {
  actions,
}
