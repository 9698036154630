import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  fundReversalRes: [],
};

const getters = {
  GET_FUND_REVERSAL: (state) => state.fundReversalRes,
};

const mutations = {
  SET_FUND_REVERSAL_RES: (state, data) => {
    state.fundReversalRes = data;
  },
};

const actions = {
  FETCH_FUNDS_REVERSAL: async function({ commit }) {
    const httpReq = {
      // const httpReq = {
      //The endpoint below will fetch all buinesses, the naming convention is wrong but I can't wait for Theo to start doing another one
      url: "/v1/businesses/getallbusinessesforfraud",
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_FUND_REVERSAL_RES", httpRes);
    return httpRes;
  },
  FETCH_SINGLE_FUND_TRANSACTIONS: async function({ commit }, { search }) {
    const httpReq = {
      // const httpReq = {
      //The endpoint below will fetch all buinesses, the naming convention is wrong but I can't wait for Theo to start doing another one
      url: `/api/EWTraction/transactionsbybusinessid/${search}`,
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_FUND_REVERSAL_RES", httpRes);
    return httpRes;
  },
  DOWNLOAD_CSV_SINGLE_FUND_TRANSACTIONS: async function({ commit }, { id }) {
    const httpReq = {
      // const httpReq = {
      //The endpoint below will fetch all buinesses, the naming convention is wrong but I can't wait for Theo to start doing another one
      url: `/api/EWTraction/transactionsbybusinessid/${id}?download=csv`,
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_FUND_REVERSAL_RES", httpRes);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
