import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/ext-http-req";

const state = {
  monitoring: {},
};

const getters = {
  GET_MONITORING_PORTAL: (state) => state.monitoring,
};

const mutations = {
  SET_MONITORING_PORTAL: (state, data) => {
    state.monitoring = data;
  },
};

const actions = {
  FETCH_MONITORING_PORTAL: async function(context) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/reporting/home`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_MONITORING_PORTAL", httpRes);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
