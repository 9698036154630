import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  loginRes: null,
  businessUserRes: null,
  checkEmailRes: null,
  signUpRes: null,
  signUserBusinessUpRes: null,
};

const getters = {
  GET_LOGIN_RES: (state) => state.loginRes,
  GET_SIGN_UP_RES: (state) => state.signUpRes,
  GET_BUSINESS_USER_RES: (state) => state.businessUserRes,
  GET_CHECK_EMAIL_RES: (state) => state.checkEmailRes,
  GET_SIGN_USER_BUSINESS_UP_RES: (state) => state.signUserBusinessUpRes,
};
const actions = {
  LOG_USER_IN: async function (context, payload) {
    const httpReq = {
      url: "/v1/auth/admin/login",
      method: "POST",
      authRequest: false,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_LOGIN_RES", httpRes);
  },
  FETCH_BUSINESS_USER_DETAILS: async function (context) {
    const httpReq = {
      url: "/v1/businesses/users/",
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_BUSINESS_USER_RES", httpRes);
    return httpRes;
  },
  CHECK_EMAIL: async function (context, payload) {
    const httpReq = {
      url: `/v1/auth/check/${payload.email}`,
      method: "GET",
      authRequest: false,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_CHECK_EMAIL_RES", httpRes);
    return httpRes;
  },
  SIGN_USER_UP: async function (context, payload) {
    const httpReq = {
      url: `/v1/auth/signup`,
      method: "POST",
      authRequest: false,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_SIGN_UP_RES", httpRes);
  },
  SIGN_USER_BUSINESS_UP: async function (context, payload) {
    const httpReq = {
      url: "/v1/businesses/",
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_SIGN_USER_BUSINESS_UP_RES", httpRes);
  },
  LOGOUT_USER: async function (context, payload) {
    const httpReq = {
      url: "/v1/auth/logout",
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};
const mutations = {
  SETUP_LOGIN_RES: (state, data) => {
    state.loginRes = data;
  },
  SETUP_BUSINESS_USER_RES: (state, data) => {
    state.businessUserRes = data;
  },
  SETUP_CHECK_EMAIL_RES: (state, data) => {
    state.checkEmailRes = data;
  },
  SETUP_SIGN_UP_RES: (state, data) => {
    state.signUpRes = data;
  },
  SETUP_SIGN_USER_BUSINESS_UP_RES: (state, data) => {
    state.signUserBusinessUpRes = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
