import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  paymentLogsRes: null,
};

const getters = {
  GET_PAYMENT_LOGS_RES: (state) => state.paymentLogsRes,
};
const actions = {
  FETCH_PAYMENT_LOGS: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/payments/reports/paymentlogswithtime/businesses/${payload.merchant}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      // url: `/v1/payments/reports/paymentlogswithtime?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999&page=1`,
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_PAYMENT_LOGS_RES", httpRes);
    return httpRes;
  },
  //Payments.vue Payment code
  FETCH_PAYMENTS_LOGS: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${payload.merchant}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      url: `/v1/payments/reports/paymentlogswithtime?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999&page=1`,
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_PAYMENT_LOGS_RES", httpRes);
    return httpRes;
  },
  DOWNLOAD_BUSINESS_PAYMENT_LOGS_CSV: async function(context, payload) {
    const httpReq = {
      url: `/v1/payments/reports/paymentlogswithtime/businesses/${payload.merchant}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999&download=csv`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // context.commit("SETUP_PAYMENT_LOGS_RES", httpRes);
    return httpRes;
  },
  FETCH_PAGINATED_PAYMENT_LOGS: async function(context, { page, date }) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/payments/reports/paymentlogswithtime?startdatetime=${date.startdatetime}T${date.startTime}:00.000&enddatetime=${date.enddatetime}T${date.endTime}:59.999&page=${page}`,
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_PAYMENT_LOGS_RES", httpRes);
    return httpRes;
  },
  DOWNLOAD_PAYMENT_LOG_CSV: async function(context, date) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/payments/reports/paymentlogswithtime?startdatetime=${date.startdatetime}T${date.startTime}:00.000&enddatetime=${date.enddatetime}T${date.endTime}:59.999&download=csv`,
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_PAYMENT_LOGS_RES", httpRes);
    return httpRes;
  },

  FETCH_BUSINESS_PAYMENT_LOGS: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/payments/reports/paymentlogswithtime/businesses/${payload.merchant.business_id}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_PAYMENT_LOGS_RES", httpRes);
  },
  FETCH_PAGINATED_BUSINESS_PAYMENT_LOGS: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/payments/reports/paymentlogswithtime/businesses/${payload.merchant}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999&page=${payload.page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_PAYMENT_LOGS_RES", httpRes);
    return httpRes;
  },
};
const mutations = {
  SETUP_PAYMENT_LOGS_RES: (state, data) => {
    state.paymentLogsRes = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
