import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = () => ({
  terminal_deployment: [],
});

const getters = {
  GET_TERMINAL_DEPLOYMENT: (state) => state.terminal_deployment,
};

const mutations = {
  SET_TERMINAL_DEPLOYMENT: (state, data) => {
    state.terminal_deployment = data;
  },
};

const actions = {
  FETCH_TERMINAL_DEPLOYMENTS: async function (
    { commit },
    { source, page, date, search, searchActive, dateFilterActive }
  ) {
    let urlHandler = "";

    urlHandler += dateFilterActive
      ? `&date_assigned_from=${date.startDate}&date_assigned_to=${date.endDate}`
      : ``;

    urlHandler += searchActive ? `&search=${search}` : ``;

    const httpReq = {
      url: `/v1/stores/admin/deployment-response?limit=100&page=${page}&is_isa=${source}${urlHandler}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_TERMINAL_DEPLOYMENT", httpRes);
    return httpRes;
  },
  DOWNLOAD_TERMINAL_DEPLOYMENTS: async function (
    { commit },
    { source, date, search, searchActive, dateFilterActive }
  ) {
    let urlHandler = "";

    urlHandler += dateFilterActive
      ? `&date_assigned_from=${date.startDate}&date_assigned_to=${date.endDate}`
      : ``;

    urlHandler += searchActive ? `&search=${search}` : ``;

    const httpReq = {
      url: `/v1/stores/admin/deployment-response?download=true&is_isa=${source}${urlHandler}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // commit("SET_TERMINAL_DEPLOYMENT", httpRes);
    return httpRes;
  },

  CREATE_STAFF_REFERRAL_CODE: async function (context, payload) {
    const httpReq = {
      url: `/v1/staff/create`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
};
