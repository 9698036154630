import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  rulesManagerRes: null,
};

const getters = {
  GET_RULES_RES: (state) => state.rulesManagerRes,
};
const actions = {
  FETCH_RULES: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: "/v1/transfer/payouts",
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "POST",
      body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_FUND_TRANSFER_RES", httpRes);
    return httpRes;
  },
};
const mutations = {
  SETUP_FUND_TRANSFER_RES: (state, data) => {
    state.rulesManagerRes = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
