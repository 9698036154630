const { NODE_ENV = "" } = process.env;

const environment = NODE_ENV.toLowerCase();

// const apiUrl = "https://tractionapps.herokuapp.com";

const apiUrl =
  window.location.hostname === "admin.tractionapps.co"
    ? "https://traction-backend.tractionapp.co"
    : "https://traction-backend-develop.tractionapps.co/";

const extApiUrl = "http://34.204.53.88:8084";

const TMP_BASE_URL = "https://transaction-monitoring.tractionapps.co/api/v1";


export { environment, apiUrl, extApiUrl, TMP_BASE_URL };
