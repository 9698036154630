import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const actions = {
  FUNDS_TRANSFER: async function(context, payload) {
    const httpReq = {
      url: "/v1/payments/createfundstransfer",
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  BANK_LIST: async function() {
    const httpReq = {
      url: "/v1/payments/gettractionbanklist",
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  BULK_UPLOAD: async function(_, payload) {
    const httpReq = {
      url: `/v1/payments/getallfundstransferstatus?page=${payload.page}`,
      method: "GET",
      authRequest: true,
      headers: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq, true);
    return httpRes;
  },

  BULK_UPLOAD_STATUS: async function(_, payload) {
    const httpReq = {
      url: `/v1/payments/getallfundstransferstatus?page=${payload.page}`,
      method: "GET",
      authRequest: true,
      headers: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq, true);
    return httpRes;
  },

  BULK_STATUS: async function(context, payload) {
    const httpReq = {
      url: `v1/payments/getfundstransferstatus/batch/${payload.ids}`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  actions,
};
