import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  unmap_terminals: [],
};

const getters = {
  GET_ALL_UNMAPPED_TERMINALS: (state) => state.unmap_terminals,
};

const mutations = {
  SET_UNMAP_TERMINALS: (state, data) => {
    state.unmap_terminals = data;
  },
};
const actions = {
  FETCH_ALL_UNMAPPED_TERMINALS: async function ({ commit }) {
    const httpReq = {
      url: "/v1/stores/admin/terminal/list?limit=100&page=1",
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_UNMAP_TERMINALS", httpRes);
    return httpRes;
  },
  FETCH_PAGINATED_ALL_UNMAPPED_TERMINALS: async function (
    { commit },
    { page, filterActive, filterBy, filterParams, searchActive, search }
  ) {
    console.log(filterParams);
    let urlHandler = `/v1/stores/admin/terminal/list?limit=100&page=${page}`;
    urlHandler += filterActive ? `&${filterBy}=${filterParams}` : ``;
    urlHandler += searchActive ? `&businessName=${search}` : ``;

    const httpReq = {
      url: urlHandler,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_UNMAP_TERMINALS", httpRes);
    return httpRes;
  },

  FETCH_FILTERED_UNMAPPED_TERMINALS: async function (
    { commit },
    { filterBy, filterParams }
  ) {
    const httpReq = {
      url: `/v1/stores/admin/terminal/list?limit=100&${filterBy}=${filterParams}`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_UNMAP_TERMINALS", httpRes);
    return httpRes;
  },
  SEARCH_UNMAP_TERMINAL: async function ({ commit }, { search }) {
    const httpReq = {
      url: `/v1/stores/admin/terminal/list?limit=100&page=1&search=${search}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_UNMAP_TERMINALS", httpRes);
    return httpRes;
  },
  DOWNLOAD_ALL_UNMAPPED_TERMINALS: async function () {
    const httpReq = {
      url: `/v1/stores/admin/terminal/list?download=true`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // commit("SET_UNMAP_TERMINALS", httpRes);
    return httpRes;
  },
  SET_UNMAP_TERMINALS: async function (context, payload) {
    const httpReq = {
      //https://tractionapp-stage.herokuapp.com/v1/kyc/verify/%7BkycId%7D/kyc-status
      // const httpReq = {
      url: `/v1/stores/admin/terminal_unmap`,
      method: "PATCH",
      authRequest: true,
      body: { terminal_id: payload.terminal_id, serial_no: payload.serial_no },
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
