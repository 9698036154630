import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  fraud: [],
  singleFraud: null,
  override: [],
};

const getters = {
  GET_FRAUD: (state) => state.fraud,
  GET_OVERRIDE: (state) => state.override,
};

const mutations = {
  SETUP_FRAUD: (state, data) => {
    state.fraud = data;
  },
  SETUP_SINGLE_FRAUD: (state, data) => {
    state.singleFraud = data;
  },
  SETUP_OVERRIDE: (state, data) => {
    state.override = data;
  },
};

const actions = {
  FETCH_ALL_FRAUD: async function ({ commit }) {
    const httpReq = {
      url: `/v1/businesses/getallbusinessesforfraud?page=1`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SETUP_FRAUD", httpRes);
    return httpRes;
  },
  FETCH_ALL_FRAUD_PORTAL: async function ({ commit }) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/businesses/getallbusinessesforfraud?page=1`,
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SETUP_FRAUD", httpRes);
    return httpRes;
  },

  FETCH_KYC_TYPE: async function ({ commit }, type) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/businesses/getallbusinessesforfraud?kyc_override=${type}`,
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SETUP_FRAUD", httpRes);
    return httpRes;
  },

  //This endpoint was written following a conversation to only show the list of business which kyc status is false.
  FETCH_OVERRIDDEN_KYC_PORTAL: async function (
    { commit },
    { page, date, status, search, dateFilter, searchFilter, filterStatus }
  ) {
    let urlHandler = `/v1/kyc/kyc-info?verificationType=DOCUMENT&page=${page}`;

    urlHandler += dateFilter
      ? `&start_date=${date.startdate}&end_date=${date.enddate}`
      : "";
    urlHandler += searchFilter ? `&business_name=${search}` : "";
    urlHandler +=
      filterStatus && status != "" ? `&exemptedStatus=${status}` : "";

    const httpReq = {
      url: urlHandler, //`&exemptedDocType=document&exemptedStatus=un-override&page=1`, //`/v1/businesses/getallbusinessesforfraud?kyc_override=all&page=1`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SETUP_OVERRIDE", httpRes);
    return httpRes;
  },
  FETCH_PAGINATED_FRAUD: async function ({ commit }, page) {
    const httpReq = {
      url: `/v1/businesses/getallbusinessesforfraud?page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SETUP_FRAUD", httpRes);
    return httpRes;
  },
  FETCH_FRAUD_TYPE: async function ({ commit }, type) {
    const httpReq = {
      url: `/v1/businesses/getallbusinessesforfraud?is_fraud=${type}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SETUP_FRAUD", httpRes);
    return httpRes;
  },
  DOWNLOAD_FRAUD_CSV: async function ({ commit }) {
    const httpReq = {
      url: `v1/businesses/getallbusinessesforfraud?is_fraud=true&download=csv`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // commit("SETUP_FRAUD", httpRes);
    return httpRes;
  },
  UPDATE_FRAUD_STATUS: async function (context, payload) {
    try {
      const httpReq = {
        // const httpReq = {
        url: `/v1/businesses/${payload[0]}/updatefraudstatus`,
        method: "POST",
        authRequest: true,
        body: { is_fraud: payload[1] },
      };
      const httpRes = await HTTP_REQUEST(httpReq);
      return httpRes;
    } catch (error) {
      return error;
    }
  },
  FETCH_SINGLE_FRAUD: async function ({ commit }, name) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/businesses/nameoremail/${name}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SETUP_FRAUD", httpRes);
    return httpRes;
  },

  UPDATE_FRAUD: async function (context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/payments/admin/updateloan`,
      method: "PATCH",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
