import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const actions = {
  GET_ALL_AIRTIME: async function({ commit }) {
    const httpReq = {
      url: `/v1/configuration/fetch/all`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    return httpRes;
  },

  UPDATE_AIRTIME_RATE: async function(context, payload) {
    const httpReq = {
      url: `/v1/configuration/update`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  CREATE_AIRTIME_RATE: async function(context, payload) {
    const httpReq = {
      url: `/v1/configuration/create`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  actions,
};
