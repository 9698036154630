export default function dropdown() {
  var dropdown = document.getElementsByClassName("dropdown-btn");
  var i;

  for (i = 0; i < dropdown.length; i++) {
    dropdown[i].addEventListener("click", function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      console.log("Clicked");
      this.classList.toggle("active");
      var dropdownContent = this.nextElementSibling;
      console.log(dropdownContent);
      if (dropdownContent.style.display === "block") {
        dropdownContent.style.display = "none";
      } else {
        dropdownContent.style.display = "block";
      }
    });
  }
}
