import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  inactiveAccounts: [],
};

const getters = {
  GET_INACTIVE_ACCOUNTS: (state) => state.inactiveAccounts,
};

const mutations = {
  SET_INACTIVE_ACCOUNTS_RES: (state, data) => {
    state.inactiveAccounts = data;
  },
};

const actions = {
  FETCH_INACTIVE_ACCOUNTS: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: "v1/payments/providus/inactive-records",
      method: "GET",
      //   body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_INACTIVE_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
  DEACTIVATE_INACTIVE_ACCOUNTS: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: "/v1/payments/providus/deactivate-account",
      method: "POST",
      body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // context.commit("SET_INACTIVE_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
  FETCH_PAGINATED_INACTIVE_ACCOUNTS: async function(context, page) {
    const httpReq = {
      // url: `/v1/businesses/getallbusinessesforfraud?page=${page}`,
      url: `/v1/payments/providus/inactive-records?pageNumber=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_INACTIVE_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
  FETCH_FILTERED_INACTIVE_ACCOUNTS: async function(context, filter) {
    const httpReq = {
      // url: `/v1/businesses/getallbusinessesforfraud?page=${page}`,
      url: `/v1/payments/providus/inactive-records?inactive_period=${filter}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_INACTIVE_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
  DOWNLOAD_FETCH_FILTERED_INACTIVE_ACCOUNTS: async function(context, filter) {
    const httpReq = {
      // url: `/v1/businesses/getallbusinessesforfraud?page=${page}`,
      url: `/v1/payments/providus/inactive-records/download?inactive_period=${filter}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_INACTIVE_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
  SEARCH_INACTIVE_ACCOUNTS: async function({ commit }, { search, searchBy }) {
    const httpReq = {
      url: `/v1/payments/providus/inactive-records?${searchBy}=${search}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_INACTIVE_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
  DOWNLOAD_SEARCHED_INACTIVE_ACCOUNTS: async function(
    { commit },
    { search, searchBy }
  ) {
    const httpReq = {
      url: `/v1/payments/providus/inactive-records/download?${searchBy}=${search}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_INACTIVE_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
  DOWNLOAD_INACTIVE_ACCOUNTS_CSV: async function(context) {
    const httpReq = {
      url: `/v1/payments/providus/inactive-records/download`,
      authRequest: true,
      method: "GET",
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // context.commit("SET_FUND_TRANSFER_RES", httpRes);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
