import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  paymentLogsResCSV: null,
  salesLogsResCSV: null,
  businessPaymentLogsResCSV: null,
};

const getters = {
  GET_PAYMENT_LOGS_RES_CSV: (state) => state.paymentLogsResCSV,
  GET_BUSINESS_PAYMENT_LOGS_RES_CSV: (state) => state.businessPaymentLogsResCSV,
  GET_SALES_LOGS_RES_CSV: (state) => state.salesLogsResCSV,
};
const actions = {
  FETCH_PAYMENT_LOGS_CSV: async function (context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/payments/reports/paymentlogswithtime?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999&page=1&download=csv`,
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_PAYMENT_LOGS_RES", httpRes);
    return httpRes;
  },

  FETCH_USERS_LOGS_CSV: async function (context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/auth/admin/users_download?start_date=${payload.startdatetime}&end_date=${payload.enddatetime}&download=csv`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_PAYMENT_LOGS_RES", httpRes);
    return httpRes;
  },

  FETCH_PRODUDCT_LOGS_CSV: async function (context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/businesses/admin/reports/download_products?start_date=${payload.startdatetime}&end_date=${payload.enddatetime}&download=csv`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_PAYMENT_LOGS_RES", httpRes);
    return httpRes;
  },

  FETCH_SALES_LOGS_CSV: async function (context, payload) {
    const httpReq = {
      url: `/v1/sales/reports/saleswithtime?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999&download=csv`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_SALES_LOGS_RES", httpRes);
    return httpRes;
  },

  FETCH_BUSINESS_PAYMENT_LOGS_CSV: async function (context, payload) {
    const httpReq = {
      // const httpReq = {
      //url: `/v1/businesses/reports/${payload.merchant.business_id}/businesseswithtime?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999&download=csv`,
      url: `/v1/businesses/reports/business/businesseswithtime?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999&download=csv`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_BUSINESS_PAYMENT_LOGS_RES", httpRes);
    return httpRes;
  },

  FETCH_INVOICES_LOGS_CSV: async function (context, payload) {
    const httpReq = {
      url: `/v1/invoices/admin/report/getallinvoices?start_date=${payload.startdatetime}T${payload.startTime}:00.000&end_date=${payload.enddatetime}T${payload.endTime}:59.999&download=csv`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  FETCH_ORDERS_LOGS_CSV: async function (context, payload) {
    const httpReq = {
      url: `/v1/orders/admin/reports/getallorders?start_date=${payload.startdatetime}T${payload.startTime}:00.000&end_date=${payload.enddatetime}T${payload.endTime}:59.999&download=csv`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};
const mutations = {
  SETUP_PAYMENT_LOGS_RES: (state, data) => {
    state.paymentLogsResCSV = data;
  },
  SETUP_SALES_LOGS_RES: (state, data) => {
    state.salesLogsResCSV = data;
  },
  SETUP_BUSINESS_PAYMENT_LOGS_RES: (state, data) => {
    state.businessPaymentLogsResCSV = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
