import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  searchedMerchants: null,
  selectedMerchant: null,
  fixedAccount: null,
  blacklistedFixedAccount: null,
  settlementAccount: null,
};

const getters = {
  GET_SEARCHED_MERCHANT_RES: (state) => state.searchedMerchants,
  GET_SELECTED_MERCHANT_RES: (state) => state.selectedMerchant,
  GET_FIXED_ACCOUNT_RES: (state) => state.fixedAccount,
  GET_BLACKLISTED_FIXED_ACCOUNT_RES: (state) => state.blacklistedFixedAccount,
  GET_SETTLEMENT_ACCOUNT_RES: (state) => state.settlementAccount,
};
const actions = {
  FETCH_SEARCH_MERCHANTS: async function (context, payload) {
    const httpReq = {
      url: `/v1/businesses/name/${payload}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_SEARCHED_MERCHANTS_RES", httpRes);
    return httpRes;
  },
  FETCH_MERCHANT_DETAILS: async function (context, payload) {
    const httpReq = {
      url: `/v1/payments/accounts/businesses/${payload}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_SELECTED_MERCHANT_RES", httpRes);
  },

  FETCH_FIXED_ACCOUNTS: async function (context, payload) {
    const httpReq = {
      url: `/v1/payments/admin/fixedaccounts/businesses?limit=100&page=1`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_FIXED_ACCOUNTS_RES", httpRes);
    return httpRes;
  },

  FETCH_PAGINATED_FIXED_ACCOUNT: async function (
    context,
    { page, filter, date, searchFilter, search }
  ) {
    let urlHandler = filter
      ? `/v1/payments/admin/fixedaccounts/businesses?page=${page}&limit=100&startdatetime=${date.startDate}&enddatetime=${date.endDate}`
      : `/v1/payments/admin/fixedaccounts/businesses?page=${page}&limit=100`;

    urlHandler += searchFilter ? `&search=${search}` : ``;
    const httpReq = {
      url: urlHandler, //`/v1/payments/admin/fixedaccounts/businesses?page=${page}&limit=100`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_FIXED_ACCOUNTS_RES", httpRes);
    return httpRes;
  },

  FETCH_BLACKLISTED_FIXED_ACCOUNTS: async function (context, payload) {
    const httpReq = {
      url: `/v1/payments/admin/fixedaccounts/businesses?blacklist=true&limit=100`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_BLACKLISTED_FIXED_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
  FETCH_PAGINATED_BLACKLISTED_FIXED_ACCOUNTS: async function (context, page) {
    const httpReq = {
      url: `/v1/payments/admin/fixedaccounts/businesses?blacklist=true&limit=100&page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_BLACKLISTED_FIXED_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
  FETCH_SETTLEMENT_ACCOUNTS: async function (context, payload) {
    const httpReq = {
      url: `/v1/payments/settlementaccount/business`,
      method: "POST",
      // method: "GET",
      body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_SETTLEMENT_ACCOUNTS_RES", httpRes);
    return httpRes;
  },

  FETCH_PAGINATED_ACCOUNTS: async function ({ commit }, { page, payload }) {
    const httpReq = {
      url: `/v1/payments/settlementaccount/business?page=${page}`,
      method: "POST",
      authRequest: true,
      headers: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SETUP_SETTLEMENT_ACCOUNTS_RES", httpRes);
    return httpRes;
  },

  DOWNLOAD_CSV_ACCOUNTS: async function ({ commit }, payload) {
    const httpReq = {
      url: `/v1/payments/settlementaccount/business?download=csv`,
      method: "POST",
      authRequest: true,
      headers: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SETUP_SETTLEMENT_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
  SEARCH_SETTLEMENT_ACCOUNTS: async function (context, payload) {
    const seachParameter = payload[0];
    const search = payload[1];
    const httpReq = {
      url: `/v1/payments/settlementaccount/business`,
      method: "POST",
      authRequest: true,
      body: {
        [seachParameter]: search,
      },
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_SETTLEMENT_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
  FILTER_SETTLEMENT_ACCOUNTS: async function (context, payload) {
    const httpReq = {
      url: `/v1/payments/settlementaccount/business`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_SETTLEMENT_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
  BLACKLIST_FIXED_ACCOUNTS: async function (context, payload) {
    const httpReq = {
      // const httpReq = {
      url: "/v1/payments/fixedaccounts/toggleblacklist",
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "POST",
      body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_FIXED_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
  FETCH_DATED_FIXED_ACCOUNT: async function (context, date) {
    console.log("Testing");
    const httpReq = {
      // const httpReq = {
      url: `/v1/payments/admin/download_fixedaccounts?start_date=${date.startDate}&end_date=${date.endDate}`,
      method: "GET",
      // body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // context.commit("SETUP_FIXED_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
  DOWNLOAD_FIXED_ACCOUNT_CSV: async function (context, date) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/payments/admin/download_fixedaccounts?start_date=${date.startDate}&end_date=${date.endDate}&download=csv`,
      method: "GET",
      // body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // context.commit("SETUP_FIXED_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
  SEARCH_FIXED_ACCOUNT: async function (context, { search }) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/payments/admin/fixedaccounts/businesses?search=${search}`,
      method: "GET",
      // body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_FIXED_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
  WHITELIST_BULK_FIXED_ACCOUNTS: async function (context, payload) {
    const httpReq = {
      // const httpReq = {
      url: "/v1/payments/fixedaccounts/bulkblacklist",
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "POST",
      body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_FIXED_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
};
const mutations = {
  SETUP_SEARCHED_MERCHANTS_RES: (state, data) => {
    state.searchedMerchants = data;
  },
  SETUP_SELECTED_MERCHANT_RES: (state, data) => {
    state.selectedMerchant = data;
  },
  SETUP_FIXED_ACCOUNTS_RES: (state, data) => {
    state.fixedAccount = data;
  },

  SETUP_BLACKLISTED_FIXED_ACCOUNTS_RES: (state, data) => {
    state.blacklistedFixedAccount = data;
  },
  SETUP_SETTLEMENT_ACCOUNTS_RES: (state, data) => {
    state.settlementAccount = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
