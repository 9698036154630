import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const actions = {
  FETCH_PROVIDUS_REPUSH: async function(context, payload) {
    const httpReq = {
      url: `/v1/payments/providus/re-push`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  PROVIDUS_RENAME: async function(context, payload) {
    const httpReq = {
      url: `/v1/payments/providus/re-name`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  actions,
};
