import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  totalBusinessLogs: [],
};

const getters = {
  GET_TOTAL_BUSINESSES: (state) => state.totalBusinessLogs,
};

const mutations = {
  SET_TOTAL_BUSINESS_RES: (state, data) => {
    state.totalBusinessLogs = data;
  },
};

const actions = {
  FETCH_TOTAL_BUSINESSES: async function(context) {
    const httpReq = {
      // const httpReq = {
      url: "/v1/businesses/reports/allbusiness",
      method: "GET",
      //   body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_TOTAL_BUSINESS_RES", httpRes);
    return httpRes;
  },
  FETCH_COUNTER_FOR_BUSINESS_LOGS: async function(context, { days }) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/businesses/reports/business?&days=${days}`,
      method: "GET",
      //   body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // context.commit("SET_TOTAL_BUSINESS_RES", httpRes);
    return httpRes;
  },
  DOWNLOAD_COUNTER_FOR_BUSINESS_LOGS_CSV: async function(context, { days }) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/businesses/reports/business?download=csv&days=${days}`,
      method: "GET",
      //   body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // context.commit("SET_TOTAL_BUSINESS_RES", httpRes);
    return httpRes;
  },
  DOWNLOAD_ALLBUSINESSES_LOGS_CSV: async function(context) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/businesses/reports/allbusiness?download=csv`,
      method: "GET",
      //   body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // context.commit("SET_TOTAL_BUSINESS_RES", httpRes);
    return httpRes;
  },
  FETCH_PAGINATED_TOTAL_BUSINESSES: async function(
    context,
    { page, date, dateFiltered }
  ) {
    const urlHandler = dateFiltered
      ? `/v1/businesses/reports/allbusiness?page=${page}&startdatetime=${date.startdatetime}T${date.startTime}:00.000&enddatetime=${date.enddatetime}T${date.endTime}:59.999`
      : `/v1/businesses/reports/allbusiness?page=${page}`;
    const httpReq = {
      url: urlHandler,
      //`/v1/businesses/reports/allbusiness?page=${page}&startdatetime=${date.startdatetime}T${date.startTime}:00.000&enddatetime=${date.enddatetime}T${date.endTime}:59.999`,
      method: "GET",
      body: date,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_TOTAL_BUSINESS_RES", httpRes);
    return httpRes;
  },
  SEARCH_TOTAL_BUSINESSES: async function({ commit }, search) {
    const httpReq = {
      url: `/v1/businesses/name/${search}`,
      method: "GET",
      // body: {},
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_TOTAL_BUSINESS_RES", httpRes);
    return httpRes;
  },
  DOWNLOAD_DATE_FILTERED_BUSINESS_LOG_CSV: async function(
    { commit },
    { date }
  ) {
    const httpReq = {
      url: `/v1/businesses/reports/allbusiness?startdatetime=${date.startdatetime}T${date.startTime}:00.000&enddatetime=${date.enddatetime}T${date.endTime}:59.999&download=csv`,
      method: "GET",
      // body: {},
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // commit("SET_TOTAL_BUSINESS_RES", httpRes);
    return httpRes;
  },
  FETCH_DATE_FILTERED_BUSINESS_LOG: async function({ commit }, { date }) {
    const httpReq = {
      // url: `/v1/businesses/reports/allbusiness?startdatetime=${date.startdatetime}&enddatetime=${date.enddatetime}`,
      url: `/v1/businesses/reports/allbusiness?startdatetime=${date.startdatetime}T${date.startTime}:00.000&enddatetime=${date.enddatetime}T${date.endTime}:59.999`,
      // url: `/v1/businesses/reports/allbusiness?startdatetime=${date.startdatetime}T${data.startTime}:00.000&enddatetime=${date.enddatetime}T${date.endTime}:59.999`,
      method: "GET",
      // body: {},
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_TOTAL_BUSINESS_RES", httpRes);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
