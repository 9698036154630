
import axios from 'axios';
import { TMP_BASE_URL } from '../../environment/environment';

const state = {
    transactions: null,
    error: null
  };
  
  const getters = {
    GET_TRANSACTIONS: (state) => state.transactions,
    GET_ERROR : state => state.error
  };
  
  const mutations = {
    SET_TRANSACTIONS: (state, data) => {
      state.transactions = data;
      state.error = null;
    },
    SET_ERROR(state, error) {
      state.error = error;
      state.transactions = null;
    }
  };

  const actions = {
    async FETCH_TRANSACTIONS({ commit }, { startDate, endDate, startTime, endTime } = {}) {
      try {
        console.log('trying to fetch data');
        
        // Get the current date and time
        const currentDate = new Date();
        const currentDateTime = currentDate.toISOString();
        const adjustedDateTimeString = currentDateTime.slice(0, 19).replace('T', ' '); // Adjust currentDateTime to include seconds

        // Calculate the start time as current time minus 30 minutes
        const startTimeDefault = new Date(currentDate.getTime() - 30 * 60000); // Subtract 30 minutes
        const startTimeString = startTimeDefault.toISOString().slice(0, 19).replace('T', ' '); // Convert to formatted string

        console.log('startTimeString:', startTimeString);
        console.log('adjustedDateTimeString:', adjustedDateTimeString);

        // Construct query parameters manually
        const start = startDate ? `${startDate} ${startTime || '00:00:00'}` : startTimeString;
        const end = endDate ? `${endDate} ${endTime || '23:59:59'}` : adjustedDateTimeString;


        const httpUrl = `${TMP_BASE_URL}/transactions/success-rate?startDate=${start}&endDate=${end}`;
        // const httpUrl = `${TMP_BASE_URL}/transactions/success-rate?startDate=${startTimeString}&endDate=${adjustedDateTimeString}`;
        // const httpUrl = `${TMP_BASE_URL}/transactions/success-rate?startDate=2024-06-03 17:20:04&endDate=2024-06-03 17:50:04`;
        console.log("Request URL:", httpUrl);

        const httpRes = await axios.get(httpUrl);
        if (httpRes.status === 200) {
          commit('SET_TRANSACTIONS', httpRes.data.result)
          console.log('RESULTS:', httpRes.data.result);
        } else {
            const error = new Error('Failed to fetch data with status code: ' + httpRes.status);    
            commit('SET_ERROR', error);      
          console.log('unable to fetch data');
        }

        return httpRes;
      } catch (error) {
        commit('SET_ERROR', error)
      }
    }
  }
  
  export default {
    state,
    getters,
    actions,
    mutations,
  };
  