import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  terminalRequestForm: null,
};

const getters = {
  GET_TERMINAL_REQUEST_FORM: (state) => state.terminalRequestForm,
};

const mutations = {
  SETUP_TERMINAL_REQUEST_FORM: (state, data) => {
    state.terminalRequestForm = data;
  },
};

const actions = {
  CREATE_NEW_REQUEST_FORM: async function({ commit }, payload) {
    const httpReq = {
      url: `/v1/stores/admin/addterminalrequest`,
      method: "POST",
      authRequest: true,
      headers: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SETUP_TERMINAL_REQUEST_FORM", httpRes);
    return httpRes;
  },
  ADD_OVERRIDE_CODE_REQUEST_FORM: async function({ commit }, payload) {
    const httpReq = {
      url: `/v1/stores/admin/new_deployment`,
      method: "POST",
      authRequest: true,
      headers: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SETUP_TERMINAL_REQUEST_FORM", httpRes);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
