import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  walletTransaction: [],
  singleWalletTransaction: [],
};

const getters = {
  GET_WALLET_TRANSACTION: (state) => state.walletTransaction,
  GET_SINGLE_WALLET_TRANSACTION: (state) => state.singleWalletTransaction,
};

const mutations = {
  SET_WALLET_TRANSACTION_RES: (state, data) => {
    state.walletTransaction = data;
  },
  SET_SINGLE_WALLET_TRANSACTION_RES: (state, data) => {
    state.singleWalletTransaction = data;
  },
};

const actions = {
  FETCH_WALLET_TRANSACTIONS: async function({ commit }) {
    const httpReq = {
      url: "/v1/businesses/getallbusinessesforfraud?has_wallet=true",
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_WALLET_TRANSACTION_RES", httpRes);
    return httpRes;
  },
  FETCH_SINGLE_WALLET_TRANSACTIONS: async function({ commit }, { search }) {
    const httpReq = {
      url: `/api/EWTraction/transactionsbybusinessid/${search}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SINGLE_WALLET_TRANSACTION_RES", httpRes);
    return httpRes;
  },

  GET_ALL_WALLETS_TRANSACTIONS_LOGS: async function(
    { commit },
    { date, isDownload }
  ) {
    const urlPath = `?startDate=${date.start_date}T${date.startTime}:00.000&endDate=${date.end_date}T${date.endTime}:59.999&isDownload=${isDownload}`;
    const httpReq = {
      url: `/api/EWTraction/allwallettransactions${urlPath}`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  REVERSE_TRANSACTION: async function({ commit }, transactionref) {
    const httpReq = {
      url: `/api/ewallet/transaction/reverse/${transactionref}`,
      method: "POST",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  SEARCH_SINGLE_WALLET_TRANSACTIONS: async function({ commit }, { search }) {
    const httpReq = {
      url: `/v1/businesses/nameoremail/${search}?haswallet=true`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_WALLET_TRANSACTION_RES", httpRes);
    return httpRes;
  },
  FETCH_PAGED_WALLET_TRANSACTIONS: async function({ commit }, page) {
    const httpReq = {
      url: `/v1/businesses/getallbusinessesforfraud?has_wallet=true&page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_WALLET_TRANSACTION_RES", httpRes);
    return httpRes;
  },
  FETCH_PAGED_SINGLE_WALLET_TRANSACTIONS: async function(
    { commit },
    { id, page }
  ) {
    const httpReq = {
      url: `api/EWTraction/transactionsbybusinessid/${id}?page=${page}`,
      // url: `/v1/businesses/getallbusinessesforfraud?has_wallet=true&page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SINGLE_WALLET_TRANSACTION_RES", httpRes);
    return httpRes;
  },
  FETCH_SEARCH_BY_TRANSACTION_REFERENCE: async function(
    { commit },
    { transactionReference }
  ) {
    const httpReq = {
      // url: `api/EWTraction/transactionsbybusinessid/${id}?page=${page}`,
      url: `api/EWTraction/transactionbyreference/${transactionReference}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // commit("SET_SINGLE_WALLET_TRANSACTION_RES", httpRes);
    return httpRes;
  },
  DOWNLOAD_CSV_SINGLE_WALLET_TRANSACTIONS: async function(
    { commit },
    { id, date }
  ) {
    const httpReq = {
      // const httpReq = {
      //The endpoint below will fetch all buinesses, the naming convention is wrong but I can't wait for Theo to start doing another one
      url: `/api/EWTraction/transactionsbybusinessid/${id}?download=csv?startDate=${date.start_date}&end_date=${date.end_date}`,
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_WALLET_TRANSACTION_RES", httpRes);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
