import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  logs: [],
};

const getters = {
  GET_ALL_VISITATION_LOGS: (state) => state.logs,
};

const mutations = {
  SET_VISITATION_LOGS: (state, data) => {
    state.logs = data;
  },
};

const actions = {
  FETCH_MERCHANT_VISITATION_LOG: async function(
    { commit },
    { page, dateFiltered, searchActive, date, search }
  ) {
    let urlHandler = `/v1/merchantvisitation/logged?page=${page}`;
    urlHandler += dateFiltered
      ? `&visitation_complete_from_date=${date.startdatetime}&visitation_complete_to_date=${date.enddatetime}`
      : ``;
    urlHandler += searchActive ? `&search=${search}` : ``;
    const httpReq = {
      url: urlHandler, //`/v1/merchantvisitation/logged`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_VISITATION_LOGS", httpRes);
    return httpRes;
  },
  DOWNLOAD_MERCHANT_VISITATION_LOG: async function(
    { commit },
    { page, dateFiltered, searchActive, date, search }
  ) {
    let urlHandler = `/v1/merchantvisitation/logged?download=true`;
    urlHandler += dateFiltered
      ? `&visitation_complete_from_date=${date.startdatetime}&visitation_complete_to_date=${date.enddatetime}`
      : ``;
    urlHandler += searchActive ? `&search=${search}` : ``;
    console.log(dateFiltered);
    const httpReq = {
      url: urlHandler, //`/v1/merchantvisitation/logged`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    // commit("SET_VISITATION_LOGS", httpRes);
    return httpRes;
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
};
