import { HTTP_REQUEST } from "../../http-config/trac-http-req";
import { apiUrl } from "../../environment/environment";
import { GET_USER_TOKEN } from "../../browser-db-config/localStorage";

const actions = {
  GET_ALL_WAITLIST: async function ({ commit }) {
    const httpReq = {
      url: `/v2/savings/wait-list-all`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    return httpRes;
  },

  DOWNLOAD_WAITLIST: async function ({ commit }, payload) {
    const url = apiUrl + "/v2/savings/wait-list-download";
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${GET_USER_TOKEN()}`);

    const response = await fetch(url, {
      method: "GET",
      headers: headers,
      responseType: "blob",
    });

    console.log(response);
    return response;
  },
};

export default {
  actions,
};
