import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  terminals: [],
};

const getters = {
  GET_ISSUES: (state) => state.issues,
};

const mutations = {
  SET_ISSUES: (state, data) => {
    state.issues = data;
  },
};

const actions = {
  FETCH_ALL_ISSUES: async function({ commit }) {
    const httpReq = {
      url: `/v1/utilities/support_issues`,
      method: "GET",
      authRequest: false,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_ISSUES", httpRes);
    return httpRes;
  },
  CREATE_NEW_SUPPORT_REQUEST: async function({ commit }, payload) {
    const httpReq = {
      url: `/v1/utilities/prospects/sendzohoemail`,
      method: "POST",
      authRequest: false,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
