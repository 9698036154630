import { GET_USER_BUSINESS_ID } from '../../browser-db-config/localStorage'
import { HTTP_REQUEST } from '../../http-config/trac-http-req'

const state = () => ({
  loans: [],
})

const getters = {
  GET_LOANS: (state) => state.loans,
}

const mutations = {
  SET_LOANS: (state, data) => {
    state.loans = data
  },
}

const actions = {
  FETCH_ALL_LOANS: async function({ commit }) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/payments/admin/getallloans`,
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    commit('SET_LOANS', httpRes)
    return httpRes
  },
  FETCH_VFD: async function({ commit }) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/payments/admin/getallloans`,
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },
  FETCH_WAITING_LISTS: async function({ commit }) {
    const httpReq = {
      url: `/v2/savings/wait-list-all `,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },
  CREATE_LOAN: async function(context, payload) {
    try {
      const httpReq = {
        // const httpReq = {
        url: `/v1/payments/admin/addloan`,
        method: 'POST',
        authRequest: true,
        body: payload,
      }
      const httpRes = await HTTP_REQUEST(httpReq)
      return httpRes
    } catch (error) {
      return error
    }
  },

  FETCH_ALL_ACCOUNTS: async function() {
    const httpReq = {
      url: `/v2/loans/loan-tracker/sweep/ft/accounts`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  GET_SINGLE_LOAN: async function(context, { id }) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/payments/admin/getloan/${id}`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  GET_ALL_VFD: async function(_, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v2/loans/loan-vfd-all`,
      method: 'POST',
      body: payload,
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  CREATE_VFD: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v2/loans/vfd/add`,
      method: 'POST',
      authRequest: true,
      body: payload,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    console.log('httpRes', httpRes)
    return httpRes
  },

  DISBURSE_VFD: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v2/loans/vfd/disburse`,
      method: 'POST',
      authRequest: true,
      body: payload,
    }
    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  GET_ALL_LOAN_TRACKER: async function() {
    const httpReq = {
      // const httpReq = {
      url: `/v2/loans/loan-tracker-all`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  GET_ALL_DISBURSEMENT_METHODS: async function() {
    const httpReq = {
      // const httpReq = {
      url: `/v2/loans/loan-disbursement-method-all`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  GET_ALL_LOAN_STATUS: async function() {
    const httpReq = {
      // const httpReq = {
      url: `/v2/loans/loan-status-all`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  GET_ALL_LOAN_TYPES: async function() {
    const httpReq = {
      // const httpReq = {
      url: `/v2/loans/loan-type-all`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  GET_ALL_LOAN_REPAYMENT_METHODS: async function() {
    const httpReq = {
      // const httpReq = {
      url: `/v2/loans/loan-repayment-method-all`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  GET_ALL_DISTRIBUTION_CHANNELS: async function() {
    const httpReq = {
      // const httpReq = {
      url: `/v2/loans/distribution-channel-all`,
      method: 'GET',
      authRequest: true,
    }

    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  MAKE_LOAN_TRACKER: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `v2/loans/loan-tracker/${GET_USER_BUSINESS_ID()}/add`,
      method: 'POST',
      authRequest: true,
      body: payload,
    }
    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  MAKE_LOAN_TRACKER_TWO: async function(context, payload) {
    let formatPayload = { ...payload }
    delete formatPayload.businessId
    const httpReq = {
      // const httpReq = {
      url: `v2/loans/loan-tracker/${payload.businessId}/add`,
      method: 'POST',
      authRequest: true,
      body: formatPayload,
    }
    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },

  //   GET_ALL_TERMINAL_PROVIDERS: async function() {
  //     const httpReq = {
  //     // const httpReq = {
  //       url: `/v1/stores/listterminalprovidernames`,
  //       method: "GET",
  //       authRequest: true,
  //     };

  //     const httpRes = await HTTP_REQUEST(httpReq);
  //     return httpRes

  //   },

  //   GET_ALL_BANKS: async function() {
  //     const httpReq = {
  //     // const httpReq = {
  //       url: `/v1/stores/listbanknames`,
  //       method: "GET",
  //       authRequest: true,
  //     };

  //     const httpRes = await HTTP_REQUEST(httpReq);
  //     return httpRes

  //   },

  //   GET_ALL_BUSINESSES: async function() {
  //     const httpReq = {
  //     // const httpReq = {
  //       url: `/v1/businesses/admin/getallbusinesses`,
  //       method: "GET",
  //       authRequest: true,
  //     };

  //     const httpRes = await HTTP_REQUEST(httpReq);
  //     return httpRes

  //   },

  UPDATE_LOAN: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/payments/admin/updateloan`,
      method: 'PATCH',
      authRequest: true,
      body: payload,
    }
    const httpRes = await HTTP_REQUEST(httpReq)
    return httpRes
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
