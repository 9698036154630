<template>
  <main class="table-font mt-5">
    <div class="flex items-center justify-between">
      <h1 class="text-xl font-bold tracking-tight text-gray-900">
        {{ title }}
      </h1>
      <div>
        <slot name="button"></slot>
      </div>
    </div>
    <p class="text-sm pt-1 text-gray-600 tracking-wide">{{ subTitle }}</p>
    <input
      v-if="!loading && tableData.length == 0"
      class="flex mt-5 rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 h-9 w-64"
      placeholder="Filter tasks..."
      v-model="search"
    />

    <LoadingBar v-if="loading" />

    <div
      class="border flex items-center justify-center flex-col py-8 rounded-md mt-10"
      v-else-if="tableData.length === 0"
    >
      <img class="w-20 f" src="@/assets/empty-box.png" alt="" />
      <p class="text-sm pt-3 text-gray-600 tracking-wide">
        No data
      </p>
    </div>

    <div v-else-if="!loading && tableData.length">
      <div class="flex items-center justify-between mt-8 ">
        <div class="flex flex-1 items-center space-x-2">
          <input
            v-if="canSearch"
            class="flex rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 h-9 w-64"
            placeholder="Filter tasks..."
            v-model="search"
          />
          <div v-else>
            <slot name="custom"></slot>
          </div>
        </div>
        <div class="relative z-20">
          <button
            @click.stop="toggleView = !toggleView"
            class="items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border border-input hover:bg-accent hover:text-accent-foreground px-3 rounded-md ml-auto hidden h-8 lg:flex"
            type="button"
            aria-haspopup="menu"
            aria-expanded="false"
            data-state="closed"
          >
            <img class="mr-2 h-4 w-4 " src="@/assets/toggle.svg" alt="" />
            View
          </button>
          <div
            v-if="toggleView"
            v-click-outside="closeView"
            class="absolute shadow-sm border rounded-md right-0 top-10 max-h-128 column bg-white overflow-auto "
          >
            <div class="p-1 w-full">
              <h1 class="px-2 py-1.5 text-sm font-semibold">Toggle columns</h1>
              <div
                role="separator"
                aria-orientation="horizontal"
                class="-mx-1 my-1 h-px bg-gray-100 dark:bg-gray-700"
              ></div>
              <draggable v-model="filterColumns">
                <div
                  v-for="(column, index) in filterColumns"
                  :key="column.value"
                  role="menuitemcheckbox"
                  aria-checked="true"
                  class="relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 capitalize"
                  data-state="checked"
                  tabindex="-1"
                  data-orientation="vertical"
                  data-radix-collection-item=""
                >
                  <span
                    class="absolute left-2 flex h-3.5 w-3.5 items-center justify-center"
                    ><span data-state="checked"></span>
                    <input
                      :value="toggleData[index].active"
                      v-model="toggleData[index].active"
                      :disabled="
                        toggleDisable &&
                          toggleData[index].active === column.active
                      "
                      type="checkbox"
                      name=""
                      id=""
                    />
                  </span>
                  <span class="truncate">
                    {{ column.name }}
                  </span>
                </div>
              </draggable>
            </div>
          </div>
        </div>
      </div>

      <div :key="key" class="rounded-md border mt-5">
        <div class="w-full overflow-x-auto table-height">
          <table class="w-full text-sm">
            <thead>
              <tr
                class="border-b rounded-tl-md rounded-tr-md sticky top-0 bg-gray-800 border-gray-200 dark:border-gray-700"
              >
                <th
                  style="border-bottom-width: 1px;"
                  v-for="column in getFilterTableDataKey"
                  :key="column.value"
                  class="px-4  h-12  whitespace-nowrap  text-left border-b font-medium text-gray-100 capitalize "
                >
                  {{ column.name }}
                </th>
                <th
                  style="border-bottom-width: 1px;"
                  class="px-4 h-12 text-left border-b font-medium text-gray-100 capitalize "
                  v-if="!!$scopedSlots.extra"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in filteredTableData"
                class="border-b  last:border-0 transition-colors text-gray-600 hover:bg-muted/50 data-[state=selected]:bg-muted"
                data-state="false"
              >
                <td
                  class="p-4    align-middle capitalize whitespace-nowrap "
                  v-for="tableData in getFilterTableDataKey"
                >
                  {{ item[tableData.value] || 'N/A' }}
                </td>
                <td v-if="!!$scopedSlots.extra">
                  <slot name="extra" :slotData="item" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class=" flex justify-end items-center mt-3 space-x-4">
        <div
          class="flex w-[100px] items-center justify-center text-sm font-medium"
        >
          Page {{ params.pageNumber }} of {{ params.totalPages }}
        </div>
        <div class="flex items-center space-x-2">
          <button
            @click="emitPageChange(1)"
            :disabled="params.pageNumber === `1`"
            :class="{
              'text-gray-400   cursor-not-allowed': params.pageNumber === `1`,
              'text-accent': params.pageNumber !== `1`,
            }"
            class="items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border border-input hover:bg-accent hover:text-accent-foreground hidden h-8 w-8 p-0 lg:flex"
          >
            <span class="sr-only">Go to first page</span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="h-4 w-4"
            >
              <polyline points="11 17 6 12 11 7"></polyline>
              <polyline points="18 17 13 12 18 7"></polyline>
            </svg></button
          ><button
            @click="emitPageChange(+params.pageNumber - 1)"
            :disabled="!params.hasPrevPage"
            :class="{
              'text-gray-400   cursor-not-allowed': !params.hasPrevPage,
              'text-accent': params.hasPrevPage,
            }"
            class="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border border-input hover:bg-accent hover:text-accent-foreground h-8 w-8 p-0"
          >
            <span class="sr-only">Go to previous page</span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="h-4 w-4"
            >
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg></button
          ><button
            @click="emitPageChange(+params.pageNumber + 1)"
            :disabled="!params.hasNextPage"
            :class="{
              'text-gray-400   cursor-not-allowed': !params.hasNextPage,
              'text-accent': params.hasNextPage,
            }"
            class="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border border-input hover:bg-accent hover:text-accent-foreground h-8 w-8 p-0"
          >
            <span class="sr-only">Go to next page</span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="h-4 w-4"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg></button
          ><button
            @click="emitPageChange(params.totalPages)"
            :disabled="params.pageNumber === params.totalPages"
            :class="{
              'text-gray-400   cursor-not-allowed':
                params.pageNumber === params.totalPages,
              'text-accent': params.pageNumber !== params.totalPages,
            }"
            class="items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border border-input hover:bg-accent hover:text-accent-foreground hidden h-8 w-8 p-0 lg:flex"
          >
            <span class="sr-only">Go to last page</span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="h-4 w-4"
            >
              <polyline points="13 17 18 12 13 7"></polyline>
              <polyline points="6 17 11 12 6 7"></polyline>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from 'moment'
import ClickOutside from 'vue-click-outside'
import draggable from 'vuedraggable'

export default {
  props: {
    title: {
      type: String,
      default: 'Welcome Back!',
    },
    subTitle: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    renderedItems: {
      type: Array,
      default: [],
    },
    params: {
      type: Object,
      default: () => {},
    },
    cycle: {},
    canSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      toggleView: false,
      toggleData: [],
      search: '',
      key: 0,
    }
  },
  directives: {
    ClickOutside,
  },
  components: {
    draggable,
  },
  watch: {
    search: function() {
      this.$emit('search', this.search)
    },
    cycle(val) {
      if (val) {
        this.initialize()
      }
    },
  },
  computed: {
    toggleDisable() {
      return this.toggleData.filter((item) => item.active).length === 1
    },
    tableColumns() {
      return this.columns
    },
    filterColumns: {
      get() {
        const keys = this.renderedItems
        let converted = [...keys].map((item) => {
          return {
            name: item.name,
            value: item.key,
            active: true,
          }
        })
        return converted
      },
      set(value) {
        this.$emit('update', value)
      },
    },
    filteredTableData() {
      return this.tableData.map((item) => {
        return this.getFilterTableDataKey.reduce((acc, key) => {
          if (key.active) {
            acc[key.value] = item[key.value]
          }
          return acc
        }, {})
      })
    },
    getFilterTableDataKey() {
      return this.toggleData.filter((item) => item.active)
    },
  },
  methods: {
    emitPageChange(pageNumber) {
      this.$emit('pageChange', pageNumber)
    },
    closeView() {
      if (this.toggleView) {
        this.toggleView = false
      }
    },
    initialize() {
      let converted = this.renderedItems.map((item) => {
        return {
          name: item.name,
          value: item.key,
          active: true,
        }
      })
      this.toggleData = converted
    },
    underscoreToCamelCase(s) {
      return s.replace(/(_\w)/g, function(m) {
        return ' ' + m[1].toUpperCase()
      })
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');

.table-font {
  font-family: 'Noto Sans JP', sans-serif;
}

.border {
  border-width: 1px !important;
}

.column {
  width: 16rem;
}

.table-head-color {
  color: #65758b;
}
.table-height {
  max-height: calc(100vh - 20rem);
}
.border-b {
  border-bottom-width: 1px;
}
</style>
