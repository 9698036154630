import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  terminalRequestsISA: {},
};

const getters = {
  GET_TERMINAL_REQUESTS_NEW: (state) => state.terminalRequestsISA,
};

const mutations = {
  SET_TERMINAL_REQUESTS_NEW_RES: (state, data) => {
    state.terminalRequestsISA = data;
  },
};

const actions = {
  FETCH_TERMINAL_REQUESTS_NEW: async function(context, source) {
    const urlPath =
      source === "loan"
        ? `v2/stores/listterminalrequest?isloan=true`
        : `v2/stores/listterminalrequest?source=${source}`;
    const httpReq = {
      // const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_TERMINAL_REQUESTS_NEW_RES", httpRes);
    return httpRes;
  },
  FETCH_TERMINAL_REQUESTS_FOLLOW_UP_NEW: async function(context, source) {
    const urlPath =
      source === "loan"
        ? `v2/stores/listterminalrequest?isloan=true&followUp=true`
        : `v2/stores/listterminalrequest?source=${source}&followUp=true`;
    const httpReq = {
      // const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_TERMINAL_REQUESTS_NEW_RES", httpRes);
    return httpRes;
  },
  FETCH_FILTERED_TERMINAL_REQUESTS_NEW: async function(
    context,
    { filterBy, filterParams, source }
  ) {
    const urlPath =
      source === "loan"
        ? `v2/stores/listterminalrequest?isloan=true&${filterBy}=${filterParams}`
        : `v2/stores/listterminalrequest?source=${source}&${filterBy}=${filterParams}`;
    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_TERMINAL_REQUESTS_NEW_RES", httpRes);
    return httpRes;
  },

  FETCH_DATED_TERMINAL_REQUESTS: async function(
    context,
    {
      filterBy,
      filterParams,
      date,
      filterActive,
      searchActive,
      page,
      dateFiltered,
      search,
      source,
      filterByDateType,
      changeLogStatus,
    }
  ) {
    let urlHandler = `/v2/stores/listterminalrequest?page=${page}`;
    urlHandler += source === "loan" ? `&isloan=true` : `&source=${source}`;

    // urlHandler += dateFiltered
    //   ? `&startdatetime=${date.startdatetime}&enddatetime=${date.enddatetime}`
    //   : ``;

    if (dateFiltered) {
      if (filterByDateType == "Deploy Date") {
        urlHandler += `&deployedDateFrom=${date.startdatetime}&deployedDateTo=${date.enddatetime}`;
      } else if (filterByDateType == "Changelog Date") {
        urlHandler += `&change_log_status=${changeLogStatus}&change_log_status_from=${date.startdatetime}&change_log_status_to=${date.enddatetime}`;
      } else {
        urlHandler += `&startdatetime=${date.startdatetime}&enddatetime=${date.enddatetime}`;
      }
    }

    if (filterActive && filterParams === "followUp") {
      urlHandler += filterActive ? `&followUp=true` : ``;
    } else if (filterActive && filterParams != "ALL") {
      urlHandler += filterActive ? `&${filterBy}=${filterParams}` : ``;
    }
    urlHandler += searchActive ? `&businessName=${search}` : ``;

    // const urlPath =
    //   source === "loan"
    //     ? `v2/stores/listterminalrequest?isloan=true&startdatetime=${date.startdatetime}&enddatetime=${date.enddatetime}`
    //     : `v2/stores/listterminalrequest?source=${source}&startdatetime=${date.startdatetime}&enddatetime=${date.enddatetime}`;
    const httpReq = {
      url: urlHandler,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_TERMINAL_REQUESTS_NEW_RES", httpRes);
    return httpRes;
  },
  SEARCH_TERMINAL_REQUESTS_NEW: async function(context, { search, source }) {
    const urlPath =
      source === "loan"
        ? `v2/stores/listterminalrequest?isloan=true&businessName=${search}`
        : `v2/stores/listterminalrequest?source=${source}&businessName=${search}`;
    const httpReq = {
      url: urlPath, //`v2/stores/listterminalrequest?source=${source}&businessName=${search}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_TERMINAL_REQUESTS_NEW_RES", httpRes);
    return httpRes;
  },
  DOWNLOAD_TERMINAL_REQUEST_NEW_CSV: async function(
    context,
    {
      filterBy,
      filterParams,
      date,
      filterActive,
      searchActive,
      page,
      dateFiltered,
      search,
      source,
      filterByDateType,
      changeLogStatus,
    }
  ) {
    let urlHandler =
      source === "loan"
        ? `/v2/stores/listterminalrequest?download=true&isloan=true`
        : `/v2/stores/listterminalrequest?download=true&source=${source}`;

    if (dateFiltered) {
      if (filterByDateType == "Deploy Date") {
        urlHandler += `&deployedDateFrom=${date.startdatetime}&deployedDateTo=${date.enddatetime}`;
      } else if (filterByDateType == "Changelog Date") {
        urlHandler += `&change_log_status=${changeLogStatus}&change_log_status_from=${date.startdatetime}&change_log_status_to=${date.enddatetime}`;
      } else {
        urlHandler += `&startdatetime=${date.startdatetime}&enddatetime=${date.enddatetime}`;
      }
    }
    if (filterActive && filterParams === "FOLLOW_UP") {
      urlHandler += filterActive ? `&followUp=true` : ``;
    } else {
      urlHandler += filterActive ? `&${filterBy}=${filterParams}` : ``;
    }
    urlHandler += searchActive ? `&businessName=${search}` : ``;

    const httpReq = {
      // const httpReq = {
      url: urlHandler, //`/v2/stores/listterminalrequest?download=csv&startdatetime=${date.startdatetime}&enddatetime=${date.enddatetime}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  DOWNLOAD_DATE_TERMINAL_REQUESTS_LOG_CSV: async function(
    context,
    { source, date }
  ) {
    const urlPath =
      source === "loan"
        ? `/v2/stores/listterminalrequest?isloan=true&download=csv&startdatetime=${date.startdatetime}&enddatetime=${date.enddatetime}`
        : `/v2/stores/listterminalrequest?source=${source}&download=csv&startdatetime=${date.startdatetime}&enddatetime=${date.enddatetime}`;
    const httpReq = {
      // const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  FETCH_PAGINATE_TERMINAL_REQUESTS_NEW: async function(
    context,
    {
      page,
      source,
      date,
      dateFiltered,
      filterActive,
      filterBy,
      filterParams,
      searchActive,
      search,
      filterByDateType,
      changeLogStatus,
    }
  ) {
    let urlHandler =
      source === "loan"
        ? `/v2/stores/listterminalrequest?isloan=true&page=${page}`
        : `/v2/stores/listterminalrequest?source=${source}&page=${page}`;

    if (dateFiltered) {
      if (filterByDateType == "Deploy Date") {
        urlHandler += `&deployedDateFrom=${date.startdatetime}&deployedDateTo=${date.enddatetime}`;
      } else if (filterByDateType == "Changelog Date") {
        urlHandler += `&change_log_status=${changeLogStatus}&change_log_status_from=${date.startdatetime}&change_log_status_to=${date.enddatetime}`;
      } else {
        urlHandler += `&startdatetime=${date.startdatetime}&enddatetime=${date.enddatetime}`;
      }
    }

    // if (source === "loan") {
    //   urlHandler = dateFiltered
    //     ? `&startdatetime=${date.startdatetime}&enddatetime=${date.enddatetime}`
    //     : `/v2/stores/listterminalrequest?isloan=true&page=${page}`;
    // } else {
    //   urlHandler = dateFiltered
    //     ? `&startdatetime=${date.startdatetime}&enddatetime=${date.enddatetime}`
    //     : `/v2/stores/listterminalrequest?source=${source}&page=${page}`;
    // }

    urlHandler += filterActive ? `&${filterBy}=${filterParams}` : ``;
    urlHandler += searchActive ? `&businessName=${search}` : ``;
    const httpReq = {
      url: urlHandler, //`v2/stores/listterminalrequest?source=${source}&page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_TERMINAL_REQUESTS_NEW_RES", httpRes);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
