import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  datasetRes: null,
};

const getters = {
  GET_DATASET_RES: (state) => state.datasetRes,
};
const actions = {
  FETCH_USERS: async function(context) {
    const httpReq = {
      url: "/v1/auth/reports/getusers?limit=100",
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_DATASET_RES", httpRes);
    return httpRes;
  },
  FETCH_PAGINATED_DATASET_USERS: async function(context, page) {
    const httpReq = {
      url: `/v1/auth/reports/getusers?limit=100&page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_DATASET_RES", httpRes);
    return httpRes;
  },
  FETCH_BUSINESSES: async function(context) {
    const httpReq = {
      url: "/v1/businesses/reports/getallbusinesses",
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_DATASET_RES", httpRes);
  },
  FETCH_PAGINATED_DATASET_BUSINESSES: async function(context, page) {
    const httpReq = {
      url: `/v1/businesses/reports/getallbusinesses?limit=100&page=${page}`,
      // url: `/v1/auth/reports/getusers?limit=100&page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_DATASET_RES", httpRes);
    return httpRes;
  },
  FETCH_SALES: async function(context) {
    const httpReq = {
      url: "/v1/sales/reports/getallsales/",
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_DATASET_RES", httpRes);
  },
  FETCH_INVOICES: async function(context) {
    const httpReq = {
      url: "/v1/invoices/admin/report/getallinvoices",
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_DATASET_RES", httpRes);
  },
  FETCH_PAGINATED_DATASET_INVOICES: async function(context, page) {
    const httpReq = {
      url: `/v1/invoices/admin/report/getallinvoices?page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_DATASET_RES", httpRes);
    return httpRes;
  },
  FETCH_ORDERS: async function(context) {
    const httpReq = {
      url: "/v1/orders/admin/reports/getallorders",
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_DATASET_RES", httpRes);
  },
  FETCH_PAGINATED_DATASET_ORDERS: async function(context, page) {
    const httpReq = {
      url: `/v1/orders/admin/reports/getallorders?page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_DATASET_RES", httpRes);
    return httpRes;
  },
  FETCH_STORES: async function(context) {
    const httpReq = {
      url: "/v1/stores/reports/allonlinestores",
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_DATASET_RES", httpRes);
  },
  FETCH_PRODUCTS: async function(context) {
    const httpReq = {
      url: "/v1/businesses/reports/getallproducts",
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_DATASET_RES", httpRes);
  },

  FETCH_PAGINATED_DATASET_PRODUCTS: async function(context, page) {
    const httpReq = {
      url: `/v1/businesses/reports/getallproducts?page=${page}&limit=100`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_DATASET_RES", httpRes);
    return httpRes;
  },
  FETCH_PAGINATED_DATASET_STORES: async function(context, page) {
    const httpReq = {
      url: `/v1/stores/reports/allonlinestores?page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_DATASET_RES", httpRes);
    return httpRes;
  },
};
const mutations = {
  SETUP_DATASET_RES: (state, data) => {
    state.datasetRes = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
