import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = () => ({
  pricing_band_lists: [],
});

const getters = {
  GET_PRICING_BAND_LISTS: (state) => state.pricing_band_lists,
};

const mutations = {
  SET_PRICING_BAND_LISTS: (state, data) => {
    state.pricing_band_lists = data;
  },
};

const actions = {
  FETCH_PRICING_BAND_LISTS: async function({ commit }) {
    const httpReq = {
      url: `/v1/payments/pricing-band`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_PRICING_BAND_LISTS", httpRes);
    return httpRes;
  },
  ASSIGN_PRICING_BAND_LISTS: async function(context, payload) {
    const httpReq = {
      url: `/v1/admin/business/${payload.business_id}/pricingBand/${payload.pricing_band_id}`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  //This is not done yet
  CREATE_PRICING_BAND_LISTS: async function(context, payload) {
    const httpReq = {
      url: `/v1/stores/admin/terminaldeploymentcode`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  PAGINATE_PRICING_BAND_LIST: async function({ commit }, page) {
    const httpReq = {
      url: `/v1/stores/admin/list_over_ride_code?limit=100&page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_PRICING_BAND_LISTS", httpRes);
    return httpRes;
  },
  DOWNLOAD_OVERRIDE_LIST: async function({ commit }, page) {
    const httpReq = {
      url: `/v1/stores/admin/list_over_ride_code?limit=100&page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_PRICING_BAND_LISTS", httpRes);
    return httpRes;
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
};
