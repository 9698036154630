<template>
  <div class="max-h-screen flex flex-col overflow-auto">
    <div class="flex-row mt-2 sm:mt-0">
      <button
        class="sm:hidden flex fixed bg-gray-50"
        @click="mobileMenuShow = !mobileMenuShow"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="currentColor"
          class="bi bi-list text-blue-800"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          />
        </svg>
      </button>
      <aside
        class="bg-gray h-screen shadow-md sm:hidden z-50"
        v-show="mobileMenuShow"
      >
        <div
          class="p-4 h-full bg-gray-800 flex flex-col"
          style="overflow: scroll"
        >
          <div class="flex flex-col flex-grow flex-shrink justify-between">
            <div class="text-sm">
              <div class="text-white p-5 rounded cursor-pointer">Workspace</div>
              <div v-for="link in links" :key="link.id">
                <router-link
                  :key="link.id"
                  :tag="!link.subLinks ? 'div' : 'button'"
                  :to="`${link.page}`"
                  class="dropdown-btn btn-full-width p-2 rounded mt-2 cursor-pointer hover:bg-gray-700 hover:text-blue-300"
                  :class="
                    $route.name.includes(link.routeName)
                      ? 'bg-gray-700 text-blue-300'
                      : 'bg-gray-900 text-white'
                  "
                >
                  {{ link.text }}
                  <svg
                    style="display: inline-block"
                    v-if="link.subLinks"
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-4 h-4 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </router-link>
                <div class="dropdown-container">
                  <router-link
                    v-for="subLink in link.subLinks"
                    :key="subLink.id"
                    :to="`${subLink.page}`"
                    class="p-2 rounded mt-2 cursor-pointer hover:bg-gray-500 hover:text-blue-300"
                    :class="
                      $route.name.includes(subLink.routeName)
                        ? 'bg-gray-500 text-blue-300'
                        : 'bg-gray-900 text-white'
                    "
                  >
                    {{ subLink.text }}
                  </router-link>
                </div>
              </div>
            </div>

            <div class="mt-2 mb-0">
              <button
                @click="logout"
                class="rounded flex items-center w-full p-3 text-white bg-red-500 cursor-pointer text-center text-sm"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                    clipRule="evenodd"
                  />
                </svg>
                <span class="font-semibold">Logout</span>
              </button>
            </div>
          </div>
        </div>
      </aside>
    </div>
    <main class="flex w-full h-screen">
      <aside
        class="w-80 h-full bg-gray shadow-md hidden sm:block"
        style="overflow: scroll"
      >
        <div
          class="p-4 h-full bg-gray-900 flex flex-col"
          style="overflow: scroll"
        >
          <div
            class="flex flex-col flex-grow flex-shrink justify-between mt-16"
          >
            <div class="text-sm">
              <div class="bg-gray-900 text-white p-5 rounded cursor-pointer">
                Workspace
              </div>
              <div
                v-for="link in links"
                :key="link.id"
                :class="link.grouped ? 'px-3' : 'p-3'"
              >
                <div
                  v-if="link.groupName"
                  class="text-white p-2 rounded-t-md bg-blue-800 mt-2"
                >
                  {{ link.groupName }}
                </div>
                <router-link
                  :key="link.id"
                  :tag="!link.subLinks ? 'div' : 'button'"
                  :to="`${link.page}`"
                  class="dropdown-btn btn-full-width p-2 rounded cursor-pointer hover:font-bold hover:text-white"
                  :class="[
                    $route.name.includes(link.routeName)
                      ? 'bg-gray-700 text-blue-300'
                      : 'bg-gray-900 text-white',
                    link.grouped ? '' : 'mt-2',
                  ]"
                >
                  {{ link.text }}
                  <svg
                    style="display: inline-block"
                    v-if="link.subLinks"
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-4 h-4 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </router-link>
                <div class="dropdown-container">
                  <router-link
                    v-for="subLink in link.subLinks"
                    :tag="!subLink.subLinkPages ? 'div' : 'button'"
                    :key="subLink.id"
                    :to="`${subLink.page}`"
                    class="dropdown-btn btn-full-width p-2 rounded mt-2 cursor-pointer hover:font-bold hover:text-white"
                    :class="
                      $route.name.includes(subLink.routeName)
                        ? 'bg-gray-500 text-blue-300'
                        : 'bg-gray-900 text-white'
                    "
                  >
                    {{ subLink.text }}
                    <svg
                      style="display: inline-block"
                      v-if="subLink.subLinkPages"
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 h-4 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </router-link>
                </div>
              </div>
            </div>

            <div class="mt-2 mb-0">
              <button
                @click="logout"
                class="rounded flex items-center w-full p-3 text-white bg-red-500 cursor-pointer text-center text-sm"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                    clipRule="evenodd"
                  />
                </svg>
                <span class="font-semibold">Logout</span>
              </button>
            </div>
          </div>
        </div>
      </aside>

      <section class="w-full p-4 overflow-scroll bg-gray-50">
        <router-view />
        <!-- <div class="w-full h-64 border-dashed border-4 p-4 text-md">
          Dashboard
        </div> -->
      </section>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import dropdown from "../assets/js/dropdown.js";
export default {
  name: "Home",
  data() {
    return {
      mobileMenuShow: false,
      links: [
        {
          id: 0,
          text: "Overview",
          page: "/overview",
          routeName: "Overview",
        },
        {
          id: 1,
          text: "Quick Access",
          page: "/quick-access",
          routeName: "QuickAccess",
        },
        {
          id: 2,
          text: "Directory",
          page: "",
          routeName: "Directory",
          subLinks: [
            {
              id: 0,
              text: "Settlement Accounts",
              page: "/settlement-accounts",
              routeName: "SettlementAccounts",
            },
            {
              id: 1,
              text: "Bank Accounts",
              page: "/bank-accounts",
              routeName: "BankAccounts",
            },
            {
              id: 2,
              text: "Datasets",
              page: "/dataset",
              routeName: "Dataset",
            },
            {
              id: 3,
              text: "Business Logs",
              page: "/business-logs",
              routeName: "BusinessLogs",
            },
            {
              id: 4,
              text: "Wema Bank Account Query",
              page: "/wema-bank-account-query",
              routeName: "WEMABankAccountQuery",
            },
            {
              id: 5,
              text: "VFD Transaction Query",
              page: "/vfd-transaction-query",
              routeName: "VFDTransactionQuery",
            },
          ],
        },
        {
          id: 3,
          text: "Transaction Logs",
          page: "",
          routeName: "Logs",
          subLinks: [
            {
              id: 0,
              text: "Payment Logs",
              page: "/payments-logs",
              routeName: "PaymentsLogs",
            },
            {
              id: 1,
              text: "Business Payment Logs",
              page: "/business-payment-logs",
              routeName: "BusinesPaymentLogs",
            },
          ],
        },
        {
          id: 4,
          text: "Product Monitoring",
          page: "",
          routeName: "ProductMonitoring",
          subLinks: [
            {
              id: 0,
              text: "Transaction Monitoring Portal",
              page: "/transaction-monitoring-portal",
              routeName: "TransactionMonitoringPortal",
            },
            {
              id: 1,
              text: "Transaction Monitoring Portal 2",
              page: "/new-transaction-monitoring-portal-2",
              routeName: "NewTransactionMonitoringPortalTwo",
            },
          ],
        },
        {
          id: 5,
          text: "Wallet",
          page: "",
          routeName: "WalletDirectory",
          subLinks: [
            {
              id: 0,
              text: "Wallet Transaction Logs",
              page: "/wallet-transaction-logs",
              routeName: "WalletTransactionLogs",
            },
            {
              id: 1,
              text: "Funds Reversal",
              page: "/funds-reversal",
              routeName: "FundsReversal",
            },
            {
              id: 2,
              text: "Wallet Management Portal",
              page: "/wallet-management-portal",
              routeName: "WalletManagementPortal",
            },
            {
              id: 3,
              text: "All Wallets Transactions Logs",
              page: "/all-wallets-transactions-logs",
              routeName: "AllWalletsTransactionsLogs",
            },
            {
              id: 4,
              text: "Transaction Reversal",
              page: "/transaction-reversal",
              routeName: "TransactionReversal",
            },
          ],
        },
        {
          id: 6,
          text: "Settings",
          page: "",
          routeName: "Settings",
          subLinks: [
            {
              id: 0,
              text: "Wallet",
              page: "/wallet",
              routeName: "Wallet",
            },
            {
              id: 1,
              text: "Cashback Activation Portal",
              page: "/cashback-activation-portal",
              routeName: "CashbackActivationPortal",
            },
            {
              id: 2,
              text: "Fraud Rules Manager",
              page: "/rules-manager",
              routeName: "RulesManager",
            },
            {
              id: 3,
              text: "Terminal Pricing",
              page: "/terminal-pricings",
              routeName: "Pricings",
            },
            {
              id: 4,
              text: "Terminal Delivery States",
              page: "/terminal-delivery-portal",
              routeName: "TerminalDeliveryPortal",
            },
            {
              id: 5,
              text: "Funds Transfer Provider Portal",
              page: "/funds-transfer-provider-portal",
              routeName: "FundsTransferProviderPortal",
            },
            {
              id: 6,
              text: "Pricing Band Portal",
              page: "/pricing-bands",
              routeName: "PricingBandPortal",
            },
            {
              id: 7,
              text: "Sendinblue Email Synch",
              page: "/sendinblue-email-synch",
              routeName: "SendinblueEmailSynch",
            },
            {
              id: 8,
              text: "Bulk Sweep",
              page: "/bulk-sweep-history",
              routeName: "BulkSweepHistory",
            },
            {
              id: 9,
              text: "Middleware Switching Portal",
              page: "/middleware-switching-portal",
              routeName: "MiddlewareSwitchingPortal",
            },
          ],
        },
        {
          id: 7,
          text: "Fraud Management",
          page: "",
          routeName: "FraudManagement",
          subLinks: [
            {
              id: 0,
              text: "Mark as Fraud",
              page: "/fraud",
              routeName: "Fraud",
            },
            {
              id: 1,
              text: "Blacklisted Accounts",
              page: "/fixed-account-blacklisted",
              routeName: "FixedAccountBlacklisted",
            },
            {
              id: 2,
              text: "Providus Repush",
              page: "/providus-repush",
              routeName: "ProvidusRepush",
            },
            {
              id: 3,
              text: "Providus Rename",
              page: "/providus-rename",
              routeName: "ProvidusRename",
            },
          ],
        },
        {
          id: 8,
          text: "Virtual Account",
          page: "",
          routeName: "VicturalAccount",
          subLinks: [
            {
              id: 0,
              text: "Fixed Accounts",
              page: "/fixed-accounts",
              routeName: "FixedAccounts",
            },
            {
              id: 1,
              text: "Inactive Accounts",
              page: "/inactive-accounts",
              routeName: "InactiveAccounts",
            },
          ],
        },
        {
          id: 9,
          text: "Verification",
          page: "",
          routeName: "Verification",
          subLinks: [
            {
              id: 0,
              text: "All Verifications",
              page: "/all-verification",
              routeName: "Verifications",
            },
            {
              id: 1,
              text: "Document Verifications",
              page: "/document-verification",
              routeName: "DocumentVerification",
            },
            {
              id: 2,
              text: "Address Verification",
              page: "/address-verification",
              routeName: "AddressVerification",
            },
            {
              id: 3,
              text: "Corporate Verification",
              page: "/corporate-verification",
              routeName: "CorporateVerification",
            },
            {
              id: 4,
              text: "KYC Override Portal",
              page: "/kyc-override-portal",
              routeName: "KYCOveridePortal",
            },
            {
              id: 5,
              text: "Retry Portal",
              page: "/retry-portal",
              routeName: "RetryPortal",
            },
          ],
        },
        {
          id: 10,
          text: "TMS Overview",
          page: "/tms-overview",
          routeName: "TMSOverview",
          groupName: "Terminal MGT System",
          grouped: true,
        },
        {
          id: 11,
          text: "Central Warehouse",
          page: "",
          routeName: "CentralWarehouse",
          grouped: true,
          subLinks: [
            {
              id: 0,
              text: "Inventory Log",
              page: "/tms-inventory-list",
              routeName: "TMSInventoryList",
            },
            {
              id: 1,
              text: "Terminal Repair",
              page: "/tms-repair-portal",
              routeName: "TMSRepairPortal",
            },
            {
              id: 2,
              text: "Terminal Dispatch - Dispatch to the Regional Center",
              page: "/tms-terminal-dispatch",
              routeName: "TMSTerminalDispatch",
            },
          ],
        },
        {
          id: 12,
          text: "Regional Center",
          page: "",
          routeName: "RegionalCenter",
          groupName: "",
          grouped: true,
          subLinks: [
            {
              id: 0,
              text: "Terminal Dispatch Request",
              page: "/tms-dispatch-request",
              routeName: "TMSDispatchRequest",
            },
            {
              id: 1,
              text: "Terminal Inventory Log",
              page: "/tms-rc-inventory-list",
              routeName: "TMSRCInventoryList",
            },
            {
              id: 2,
              text: "Terminal Deployment",
              page: "/cw-terminal-deployment",
              routeName: "CWTerminalDeployment",
            },
          ],
        },
        {
          id: 13,
          text: "Terminals",
          page: "",
          routeName: "Terminals",
          grouped: true,
          subLinks: [
            {
              id: 0,
              text: "All Terminals",
              page: "",
              routeName: "Terminal",
            },
            {
              id: 1,
              text: "Unmap Terminals",
              page: "/unmap-terminals",
              routeName: "UnmapTerminals",
            },
            {
              id: 3,
              text: "Terminal History",
              page: "/terminal-history",
              routeName: "TerminalHistory",
            },
            {
              id: 4,
              text: "Terminal Uploads",
              page: "/terminal-uploads",
              routeName: "TerminalUploads",
            },
            {
              id: 5,
              text: "Override Code",
              page: "/terminal-override-code",
              routeName: "TerminalOverrideCode",
            },
            {
              id: 6,
              text: "Terminal Deployment - MSO",
              page: "/terminal-deployment-mso",
              routeName: "TerminalDeploymentMSO",
            },
            {
              id: 7,
              text: "Terminal Deployment - DSR",
              page: "/terminal-deployment-dsr",
              routeName: "TerminalDeploymentDSR",
            },
          ],
        },
        {
          id: 14,
          text: "Terminal Request Forms",
          page: "",
          routeName: "Terminals",
          grouped: true,
          subLinks: [
            {
              id: 0,
              text: "Request Form - Key Accounts",
              page: "/request-terminals-keyaccounts",
              routeName: "TerminalRequestFormKeyAccounts",
            },
            {
              id: 1,
              text: "Request Form - Telesales",
              page: "/request-terminals-telesales",
              routeName: "TerminalRequestFormTelesales",
            },
            {
              id: 2,
              text: "Request Form - Cross Selling",
              page: "/request-terminals-cross-selling",
              routeName: "TerminalRequestFormCrossSelling",
            },
          ],
        },
        {
          id: 15,
          text: "Terminal Requests",
          page: "",
          routeName: "Terminals",
          grouped: true,
          subLinks: [
            {
              id: 0,
              text: "Terminal Requests - All",
              page: "/terminal-requests-all",
              routeName: "TerminalRequestsAll",
            },
            {
              id: 1,
              text: "Terminal Requests - Key Accounts",
              page: "/terminal-requests-key-accounts-view",
              routeName: "TerminalRequestKeyAccountsView",
            },
            {
              id: 2,
              text: "Terminal Requests - Telesales",
              page: "/terminal-requests-telesales-view",
              routeName: "TerminalRequestTelesalesView",
            },
            {
              id: 3,
              text: "Terminal Requests - ISA",
              page: "/terminal-requests-isa-view",
              routeName: "TerminalRequestISAView",
            },
            {
              id: 4,
              text: "Terminal Requests - Web/Mobile",
              page: "/terminal-requests-mobile-and-web-view",
              routeName: "TerminalRequestMobileAppView",
            },
            {
              id: 5,
              text: "Terminal Requests - Loan",
              page: "/terminal-requests-loan-view",
              routeName: "TerminalRequestLoanView",
            },
            {
              id: 6,
              text: "Terminal Requests - DSR",
              page: "/terminal-requests-dsr-view",
              routeName: "TerminalRequestDSRView",
            },
            {
              id: 7,
              text: "Terminal Requests - Cross Selling",
              page: "/terminal-requests-cross-selling-view",
              routeName: "TerminalRequestCrossSellingView",
            },
          ],
        },
        {
          id: 16,
          text: "Terminal Retrieval",
          page: "/tms-terminal-retrieval",
          routeName: "TMSTerminalRetrieval",
          grouped: true,
        },
        {
          id: 17,
          text: "Operations",
          page: "",
          routeName: "Operations",
          subLinks: [
            {
              id: 0,
              text: "POS Terminal Receipt",
              page: "/pos-terminal-receipt",
              routeName: "POSReceipt",
            },
            {
              id: 1,
              text: "Staff Referral Code",
              page: "/staff-referral-code",
              routeName: "StaffReferralCode",
            },
            {
              id: 2,
              text: "Password Reset",
              page: "/password-reset",
              routeName: "PasswordReset",
            },
            {
              id: 3,
              text: "Settlements",
              page: "/settlements",
              routeName: "Settlements",
            },
          ],
        },
        {
          id: 18,
          text: "Notifications",
          page: "",
          routeName: "Notification",
          subLinks: [
            {
              id: 0,
              text: "Notifications",
              page: "/notifications",
              routeName: "Notifications",
            },
            {
              id: 1,
              text: "Broadcast Portal",
              page: "/broadcast-portal",
              routeName: "BroadcastPortal",
            },
          ],
        },
        {
          id: 26,
          text: "Loans",
          page: "",
          routeName: "Loans",
          subLinks: [
            {
              id: 0,
              text: "Loan Requests",
              page: "/loans",
              routeName: "Loans",
            },
            {
              id: 1,
              text: "Loan Reporting Page",
              page: "/reporting-page",
              routeName: "ReportingPage",
            },
            {
              id: 2,
              text: "Loan Dashboard",
              page: "/reporting-page/view",
              routeName: "ReportingPageView",
            },
          ],
        },
        {
          id: 19,
          text: "VFD Loans Portal",
          page: "",
          routeName: "VFDPortal",
          subLinks: [
            {
              id: 0,
              text: "VFD Crediting Portal",
              page: "/vfd-portal",
              routeName: "VFDPortal",
            },
            {
              id: 1,
              text: "VFD Loan Sweep",
              page: "/vfd-sweep",
              routeName: "VFDLoanSweep",
            },

            // {
            //   id: 3,
            //   text: "Create Loan",
            //   page: "/vfd-portal/create-loan",
            //   routeName: "VFDLoanDashboardCreateLoan",
            // },
          ],
        },
        //yc {
        //   id: 17,
        //   text: "Loan Dashboard",
        //   page: "/vfd-portal/loan-dashboard",
        //   routeName: "VFDLoanDashboard",
        // },
        {
          id: 20,
          text: "Downloads",
          page: "/downloads",
          routeName: "Downloads",
        },
        {
          id: 21,
          text: "Savings Waitlist",
          page: "/waitlist",
          routeName: "Waitlist",
        },
        {
          id: 22,
          text: "Fund transfer",
          page: "",
          routeName: "FundTransfer",
          subLinks: [
            {
              id: 0,
              text: "Funds Transfer",
              page: "/funds-transfer",
              routeName: "FundsTransfer",
            },
            {
              id: 1,
              text: "Upload Portal",
              page: "/upload-portal",
              routeName: "Upload",
            },
          ],
        },
        {
          id: 23,
          text: "Merchant Support App MGT",
          page: "",
          routeName: "",
          subLinks: [
            {
              id: 0,
              text: "Merchant Support Visitation Form Response",
              page: "/merchant-support-visitation-form-response",
              routeName: "MerchantSupportVisitationList",
            },
            {
              id: 1,
              text: "Adhoc Visitation Form",
              page: "/adhoc-visitation",
              routeName: "AdhocVisitation",
            },
            {
              id: 2,
              text: "Support Deployment",
              page: "/support-deployment",
              routeName: "SupportDeployment",
            },
            {
              id: 3,
              text: "Retrieval Response",
              page: "/retrieval-response",
              routeName: "RetrievalResponse",
            },
            {
              id: 4,
              text: "Form Responses",
              page: "/form-responses",
              routeName: "FormResponses",
            },
            {
              id: 5,
              text: "SIM MGT",
              page: "/sim-management",
              routeName: "SimManagement",
            },
            {
              id: 6,
              text: "Terminal Mastersheet",
              page: "/terminal-mastersheet",
              routeName: "TerminalMastersheet",
            },
            {
              id: 7,
              text: "Terminal Location Sheet",
              page: "/terminal-location-sheet",
              routeName: "TerminalLocationSheet",
            },
            {
              id: 8,
              text: "Terminal Weekly Visitation Sheet",
              page: "/terminal-weekly-visitation-sheet",
              routeName: "TerminalWeeklyVisitationSheet",
            },
            {
              id: 9,
              text: "Merchant Visitation Log",
              page: "/merchant-visitation-log",
              routeName: "MerchantVisitationLog",
            },
            {
              id: 10,
              text: "Terminal Retrieval",
              page: "/terminal-retrieval-response",
              routeName: "TerminalRetrievalResponse",
            },
          ],
        },
        {
          id: 24,
          text: "CSR MGT",
          page: "",
          routeName: "",
          subLinks: [
            {
              id: 0,
              text: "Account List",
              page: "/csr-account-list",
              routeName: "CSRAccountList",
            },
            {
              id: 1,
              text: "Onboarded Merchant",
              page: "/csr-onboarded-merchants",
              routeName: "CSROnboardedMerchants",
            },
            {
              id: 2,
              text: "Terminal Deployment",
              page: "/csr-terminal-deployment",
              routeName: "CSRTerminalDeployment",
            },
          ],
        },
        {
          id: 25,
          text: "Admin MGT",
          page: "",
          routeName: "",
          subLinks: [
            {
              id: 0,
              text: "All Admins",
              page: "/admin",
              routeName: "Admin",
            },
            {
              id: 1,
              text: "Permissions",
              page: "/permissions",
              routeName: "Permissions",
            },
          ],
        },

        //...
      ],
    };
  },
  components: {},
  created() {},
  methods: {
    async logout() {
      const user_id = JSON.parse(localStorage.getItem("traction-app-user-data"))
        .user._id;
      try {
        let res = await this.$store.dispatch("LOGOUT_USER", { user_id });
        if (res.status) {
          this.$router.replace({ name: "Login" });
          localStorage.clear();
        }
      } catch (error) {
        console.log(error);
      }

      this.isLoading = false;
    },
  },
  mounted() {
    dropdown();
  },
  watch: {
    $route(to, from) {
      this.mobileMenuShow = false;
    },
  },
};
</script>

<style scoped>
/* Add an active class to the active dropdown button */
.active {
  background-color: green;
  color: white;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
  display: none;
  background-color: #262626;
  padding-left: 8px;
}

.dropdown-container a {
  display: block;
  color: #fff;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-sub-container {
  display: none;
  background-color: #262626;
  padding-left: 8px;
}

.dropdown-sub-container a {
  display: block;
  color: #fff;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;
}

.btn-full-width {
  width: stretch;
  text-align: left;
}
</style>
