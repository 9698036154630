import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const actions = {
  GET_CURRENT_TRANSFER_FEE: async function({ commit }) {
    const httpReq = {
      url: `api/EWTraction/gettractionbanktransferfee`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    return httpRes;
  },

  UPDATE_FEE: async function(context, { value }) {
    const httpReq = {
      url: `api/EWTraction/updatetractionbanktransferfee/${value} `,
      method: "POST",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  actions,
};
