function SAVE_LOCAL_DB_DATA(storageName, item) {
	const data = JSON.stringify(item);
	return localStorage.setItem(storageName, data);
}

function DELETE_LOCAL_DB_DATA(storageName) {
	localStorage.removeItem(storageName);
}

function GET_LOCAL_DB_DATA(storageName, deleteData = false) {
	const data = localStorage.getItem(storageName);
	if (deleteData) {
		DELETE_LOCAL_DB_DATA(storageName);
	}
	return JSON.parse(data);
}

function GET_USER_TOKEN() {
	let userData = GET_LOCAL_DB_DATA('traction-app-user-data');
	return userData ? userData.token : null;
}

function GET_USER_DATA() {
	let userData = GET_LOCAL_DB_DATA('traction-app-user-data');
	if (userData) {
		return userData ? userData.user : {};
	}
}

function GET_USER_BUSINESS_ID() {
	let userBusinessData = GET_LOCAL_DB_DATA(
		'traction-app-current-user-business-data'
	);
	if (userBusinessData) {
		return userBusinessData._id;
	}
}

function GET_USER_BUSINESS_DATA() {
	let userBusinessData = GET_LOCAL_DB_DATA(
		'traction-app-current-user-business-data'
	);
	if (userBusinessData) {
		return userBusinessData;
	}
}

export {
	SAVE_LOCAL_DB_DATA,
	GET_USER_BUSINESS_DATA,
	GET_LOCAL_DB_DATA,
	GET_USER_DATA,
	GET_USER_TOKEN,
	GET_USER_BUSINESS_ID,
	DELETE_LOCAL_DB_DATA,
};
