import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  admin: [],
  locations: [],
  departments: [],
  users: [],
  user_permissions: [],
  dept_permissions: [],
};

const getters = {
  GET_ALL_ADMINS: (state) => state.admin,
  GET_LOCATIONS: (state) => state.locations,
  GET_ALL_DEPARTMENTS: (state) => state.departments,
  GET_ALL_USERS: (state) => state.users,
  GET_USER_PERMISSION: (state) => state.user_permissions,
  GET_DEPARTMENT_PERMISSIONS: (state) => state.dept_permissions,
};

const mutations = {
  SET_ADMIN_RES: (state, data) => {
    state.admin = data;
  },
  SET_LOCATIONS_RES: (state, data) => {
    state.locations = data;
  },
  SET_DEPARTMENTS_RES: (state, data) => {
    state.departments = data;
  },
  SET_USERS_RES: (state, data) => {
    state.users = data;
  },
  SET_USER_PERMISSIONS: (state, data) => {
    state.user_permissions = data;
  },
  SET_DEPT_PERMISSIONS: (state, data) => {
    state.dept_permissions = data;
  },
};

const actions = {
  CREATE_ADMIN: async function (context, payload) {
    const httpReq = {
      url: "/v1/auth/admin/signup",
      method: "POST",
      body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_ADMIN_RES", httpRes);
    return httpRes;
  },
  FETCH_STATES: async function (context) {
    const httpReq = {
      url: "/v1/location/states",
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_LOCATIONS_RES", httpRes);
    return httpRes;
  },
  FETCH_DEPARTMENTS: async function (context) {
    const httpReq = {
      url: "/v1/auth/admin/get-departments",
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_DEPARTMENTS_RES", httpRes);
    return httpRes;
  },
  FETCH_ALL_ADMINS: async function (
    context,
    { filterByDept, department, filterBySearch, search, page }
  ) {
    let urlPath = `/v1/auth/admin/list-admins?page=${page}`;
    urlPath += filterByDept ? `&departments=${department}` : "";
    // urlPath += filterByDept && filterBySearch ? "&" : "";
    urlPath += filterBySearch ? `&search=${search}` : "";

    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_ADMIN_RES", httpRes);
    return httpRes;
  },
  FETCH_ADMINS_BY_ROLE: async function (context, { page, role, search }) {
    let urlPath = `/v1/auth/admin/list-admins?page=${page}`;
    urlPath += role != "" ? `&role=${role}` : "";
    urlPath += search != "" ? `&search=${search}` : "";

    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_ADMIN_RES", httpRes);
    return httpRes;
  },

  FETCH_USER_PERMISSION_BY_USER_ID: async function (context, { id }) {
    const httpReq = {
      url: `/v1/auth/admin/${id}/permissions`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_USER_PERMISSIONS", httpRes);
    return httpRes;
  },

  FETCH_DEPT_PERMISSION_BY_DEPT_ID: async function (context, { id }) {
    const httpReq = {
      url: `/v1/auth/admin/department/${id}/permissions`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_DEPT_PERMISSIONS", httpRes);
    return httpRes;
  },
  SET_DEPT_PERMISSION_BY_DEPT_ID: async function (context, payload) {
    const httpReq = {
      url: `v1/auth/admin/department/${payload.dept_id}`,
      method: "PATCH",
      body: { permissions: [payload.permission] },
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  REMOVE_DEPT_PERMISSION_BY_DEPT_ID: async function (context, payload) {
    const httpReq = {
      url: `v1/auth/admin/department/${payload.dept_id}/revoke-permission`,
      method: "PATCH",
      body: { permission: payload.permission },
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  SET_USER_PERMISSION_BY_USER_ID: async function (context, payload) {
    const httpReq = {
      url: `/v1/auth/admin/grant-permission`,
      method: "POST",
      body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  DELETE_USER_PERMISSION_BY_USER_ID: async function (context, payload) {
    const httpReq = {
      url: `v1/auth/admin/revoke-permission/${payload.permission}/${payload.user_id}`,
      method: "DELETE",
      // body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  //https://tractionisa-stage.herokuapp.com/v1/admin/user/all?role=subadmin

  CHANGE_ROLE_ADMIN: async function (context, { id, payload }) {
    const httpReq = {
      url: `/v1/admin/role/${id}`,
      method: "PATCH",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  DELETE_ADMIN: async function (context, { id }) {
    const httpReq = {
      url: `/v1/auth/admin/${id}/delete`,
      method: "DELETE",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  RESET_PASSWORD_ADMIN: async function (context, { email }) {
    const httpReq = {
      url: "/v1/auth/admin/password/reset",
      method: "POST",
      body: { email },
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  RESET_PASSWORD_ADMIN_NO_AUTH: async function (context, { email }) {
    const httpReq = {
      url: "/v1/auth/admin/password/reset",
      method: "POST",
      body: { email },
      // authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  SET_USER_PASSWORD: async function (context, payload) {
    const httpReq = {
      url: `v1/auth/admin/password/set`,
      method: "POST",
      authRequest: false,
      body: payload,
      authRequest: false,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
