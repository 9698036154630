import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  dispacthList: [],
  inventoryList: [],
  repairList: [],
  retrievedTMSTerminals: [],
  tms_summary: null,
};

const getters = {
  GET_DISPATCH_LIST: (state) => state.dispacthList,
  GET_INVENTORY_LIST: (state) => state.inventoryList,
  GET_REPAIR_LIST: (state) => state.repairList,
  GET_TMS_RETRIEVED_TERMINALS: (state) => state.retrievedTMSTerminals,
  GET_TMS_SUMMARY: (state) => state.tms_summary,
};

const mutations = {
  SET_DISPATCH_LIST: (state, data) => {
    state.dispacthList = data;
  },
  SET_INVENTORY_LIST: (state, data) => {
    state.inventoryList = data;
  },
  SET_REPAIR_LIST: (state, data) => {
    state.repairList = data;
  },
  SET_RETRIEVED_TMS_TERMINALS: (state, data) => {
    state.retrievedTMSTerminals = data;
  },
  SET_TMS_SUMMARY: (state, data) => {
    state.tms_summary = data;
  },
};
const actions = {
  FETCH_DISPATCH_LIST: async function (
    { commit },
    {
      page,
      search,
      dateFilter,
      date,
      terminalTypeFilter,
      terminalType,
      regionalCenter,
      regionalCenterFilter,
    }
  ) {
    let urlPath = `v1/admin/tms/dispatch-inventory?page=${page}`;
    urlPath += search != "" ? `&search=${search}` : "";

    urlPath += dateFilter
      ? `&allocation_start_date=${date.startDate}&allocation_end_date=${date.endDate}`
      : "";

    urlPath += terminalTypeFilter ? `&terminaltype=${terminalType}` : "";

    urlPath += regionalCenterFilter ? `&region=${regionalCenter}` : "";

    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_DISPATCH_LIST", httpRes);
    return httpRes;
  },
  FETCH_INVENTORY_LIST: async function (
    { commit },
    { page, search, date, dateFilter, terminalTypeFilter, terminalType }
  ) {
    let urlPath = `v1/admin/tms/inventory?page=${page}`;
    urlPath += search != "" ? `&search=${search}` : "";
    urlPath += dateFilter
      ? `&start_date_time=${date.startDate}&end_date_time=${date.endDate}`
      : "";

    urlPath += terminalTypeFilter ? `&terminal_type=${terminalType}` : "";

    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_INVENTORY_LIST", httpRes);
    return httpRes;
  },

  FETCH_RETRIEVED_TMS_TERMINALS: async function (
    { commit },
    { page, search, date, dateFilter, terminalTypeFilter, terminalType }
  ) {
    let urlPath = `v1/admin/tms/terminals/retrieved?page=${page}`;
    urlPath += search != "" ? `&business_name=${search}` : "";
    urlPath += dateFilter
      ? `&retrieval_date_from=${date.startDate}&retrieval_date_to=${date.endDate}`
      : "";

    urlPath += terminalTypeFilter ? `&terminal_type=${terminalType}` : "";

    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_RETRIEVED_TMS_TERMINALS", httpRes);
    return httpRes;
  },

  FETCH_REPAIR_LIST: async function ({ commit }, { page, search }) {
    let urlPath = `v1/admin/tms/repair-inventory?page=${page}`;
    urlPath += search != "" ? `&search=${search}` : "";
    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_REPAIR_LIST", httpRes);
    return httpRes;
  },
  FETCH_TMS_SUMMARY: async function ({ commit }) {
    let urlPath = `v1/admin/tms/inventory/summary`;
    // urlPath += search != "" ? `&search=${search}` : "";
    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_TMS_SUMMARY", httpRes);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
