import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  settlements: [],
  settlement: [],
};

const getters = {
  GET_ALL_SETTLEMENTS: (state) => state.settlements,
  GET_SETTLEMENT: (state) => state.settlement,
};

const mutations = {
  SET_SETTLEMENTS_RES: (state, data) => {
    state.settlements = data;
  },
  SET_SETTLEMENT_RES: (state, data) => {
    state.settlement = data;
  },
};

const actions = {
  FETCH_SETTLEMENTS: async function (
    context,
    { startDate, endDate, page_size, status }
  ) {
    const httpReq = {
      url: `/v2/settlements/groups?start_date=${startDate}&end_date=${endDate}&page_size=${page_size}&status=${status}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_SETTLEMENTS_RES", httpRes);
    return httpRes;
  },
  FETCH_SETTLEMENT: async function (
    context,
    { group_code, page_size, status }
  ) {
    const httpReq = {
      url: `/v2/settlements/group-transactions?group_code=${group_code}&page_size=${page_size}&status=${status}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_SETTLEMENT_RES", httpRes);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
