import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  all_sheets: [],
  version_history: [],
};

const getters = {
  GET_ALL_SHEETS: (state) => state.all_sheets,
  GET_ALL_VERSION_HISTORY: (state) => state.version_history,
};

const mutations = {
  SET_SHEET: (state, data) => {
    state.all_sheets = data;
  },
  SET_VERSION_HISTORY: (state, data) => {
    state.version_history = data;
  },
};

const actions = {
  UPLOAD_SHEET: async function(context, { payload, source }) {
    const httpReq = {
      url: `/v1/merchantvisitation/${source}`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  FETCH_SHEETS: async function({ commit }, source) {
    const httpReq = {
      url: `/v1/merchantvisitation/${source}`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SHEET", httpRes);
    return httpRes;
  },
  SEARCH_SHEET: async function(
    { commit },
    { search, source, versionHistoryActive, versionHistory }
  ) {
    let urlPath = `/v1/merchantvisitation/${source}?search=${search}`;
    urlPath += versionHistoryActive ? `&version=${versionHistory}` : "";

    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SHEET", httpRes);
    return httpRes;
  },

  PAGINATE_SHEET: async function(
    { commit },
    { page, search, searchActive, source, versionHistoryActive, versionHistory }
  ) {
    let urlPath = `/v1/merchantvisitation/${source}?page=${page}`;
    urlPath += searchActive ? `&search=${search}` : ``;
    urlPath += versionHistoryActive ? `&version=${versionHistory}` : "";

    const httpReq = {
      url: urlPath, //`&search=${search}`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SHEET", httpRes);
    return httpRes;
  },
  FETCH_VERSION_HISTORY: async function({ commit }, { source }) {
    const httpReq = {
      url: `/v1/merchantvisitation/${source}/versions`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_VERSION_HISTORY", httpRes);
    return httpRes;
  },
  LOAD_VERSION_HISTORY: async function({ commit }, { source, version }) {
    const httpReq = {
      url: `/v1/merchantvisitation/${source}?version=${version}`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SHEET", httpRes);
    return httpRes;
  },
  SEARCH_VERSION_HISTORY: async function(
    { commit },
    { tag, searchByDateFrom, searchByDateTo, source }
  ) {
    let urlPath = `/v1/merchantvisitation/${source}/versions`;
    urlPath += tag !== "" ? `?search=${tag}` : "";
    urlPath +=
      searchByDateFrom !== "" && searchByDateTo !== "" && tag !== ""
        ? `&upload_from_date=${searchByDateFrom}&upload_to_date=${searchByDateTo}`
        : searchByDateFrom !== "" && searchByDateTo !== "" && tag === ""
        ? `?upload_from_date=${searchByDateFrom}&upload_to_date=${searchByDateTo}`
        : "";

    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_VERSION_HISTORY", httpRes);
    return httpRes;
  },
  PAGINATE_VERSION_HISTORY: async function(
    { commit },
    {
      tag,
      searchByDateFrom,
      searchByDateTo,
      source,
      page,
      searchVersionHistoryActive,
    }
  ) {
    let urlPath = `/v1/merchantvisitation/${source}/versions?page=${page}`;
    urlPath += tag !== "" && searchVersionHistoryActive ? `&search=${tag}` : "";
    urlPath +=
      searchByDateFrom !== "" &&
      searchByDateTo !== "" &&
      searchVersionHistoryActive
        ? `&upload_from_date=${searchByDateFrom}&upload_to_date=${searchByDateTo}`
        : "";

    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_VERSION_HISTORY", httpRes);
    return httpRes;
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
};
