import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const actions = {
  BULK_UPLOAD_EMAILS: async function({ commit }, { emails }) {
    const uploadFormData = new FormData();
    uploadFormData.append("email", emails);

    const httpReq = {
      url: "/v1/businesses/sync/bulkuploadcontact",
      method: "POST",
      authRequest: true,
      body: uploadFormData,
      fileUpload: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  EMAIL_SYNCH_ALL: async function() {
    const httpReq = {
      url: `/v1/businesses/sync/syncallcontact`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  actions,
};
